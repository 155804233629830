
.taxInvoiceContainerOuter{
    max-width: 900px;
    margin: auto;
    border: 1px solid var(--black);
}

.taxInvoiceContainer{
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 60px 30px 60px 30px;
}

.taxInvoiceHeader{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.taxInvoiceHeader img{
    width: 50%;
}

.taxInvoiceHeader p{
    font-size: 16px;
    color: var(--grey);
    font-family: 'ns-semibold';
}

.taxHeading{
    text-align: center;
    color: var(--dark-grey);
    margin-top: 20px !important;
    font-size: 16px;
    font-family: 'ns-bold';
}

.taxInvoiceHeader .taxDetailText{
    color: var(--dark-grey);
    font-family: 'ns-regular';
}

.taxInvoiceHeader .taxDetailText span{
    color: var(--black);
    font-family: 'ns-bold';
}
.taxStudentInfo{
    margin-top: 20px;
}
.taxStudentInfo .name{
    color: var(--black);
    font-family: 'ns-bold';
}
.taxStudentInfo .name span{
    font-family: 'ns-regular';
}
.taxStudentInfo {
    color: var(--grey);
}

.taxStudentInfo span{
    font-family: 'ns-bold';
    color: var(--black);
}

.taxInvoiceContainer hr {
    border-color: black;
    margin-bottom: 30px;
}
.stampImg{
    top: -36px !important;
    width: 25%;
}
.taxInvoice .receipt-text{
    color: var(--grey);
}
.taxInvoice{
    margin-bottom: 30px;
}



