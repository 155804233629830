.loan_popup .loan-options{
    flex-wrap: wrap;
}

.loan_popup .loan-options .form-grp{
    width: 100%;
}   

.loan_popup .loan-step-content{
    padding-left: 20px;
    width: 100%;
}

.loan_popup .border-box{
    height: 100%;
    margin-bottom: 0;
}

.loan_popup .btn-grp .accept-btn{
    font-size: 16px;
    gap: 5px;
}