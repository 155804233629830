.form-upload .form .create-input{
    width: 71%;
}
.addCourse-inner{
    overflow: auto;
    height: calc(100vh - 80px);
}

.addCourse2{
    height:  calc(100vh - 150px);
    overflow: hidden;
}