* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.receipt-container {
    background-color: white;
    
    width: 100%;

    height: 100%;
    
    padding: 60px 30px 60px 30px;

}

.receipt-header {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.receipt-text {
    line-height: 150%;
}

.receipt-table {
    margin-bottom: 30px;
    border: 1px solid #E8E8E8;
    border-collapse: collapse;
    width: 100%;
    text-align: center;
}

.receipt-table th {
    background: #4E5E65;
    color: white;
    padding: 20px;
    border: 1px solid #E8E8E8;
}

.receipt-table td {
    border: 1px solid #E8E8E8;
    padding: 20px;
}
.receipt-container hr{
    border-color: black;
    margin-bottom: 30px;
}
.stamp{
    margin-bottom: 30px;
    position: relative;
}
.stamp img{
    top: 12px;
    position: absolute;
}
.receipt-footer{
    
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.location{
    width: 45%;
    gap: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    line-height: 120%; 
}
.location img{
    padding: 10px;
    border-radius: 50%;
    background: #fad133
}
.initCap{
    text-transform: capitalize;
}
.fee-recipt-body{
    padding: 0;
    max-height: calc(100vh - 200px);
}
.receipt-container-outer{
    max-width: 900px;
    margin: auto;
    border: 1px solid var(--black);
}
.receipt-popup-container{
    overflow: auto;
    width: calc(100% - 130px);
    max-height:calc(100vh - 70px) ;
}
.receipt-popup-header .popup-heading{
    justify-content: space-between;
}
.popup-right{
    display: flex;
    gap: 10px;
    align-items: center;
}
.receipt-popup-header .popup-right button{
    font-size: 11px;
}