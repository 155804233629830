.editFeePopup {
  width: 100%;
  max-width: 700px;
  height: 100%;
  max-height: 700px;
}

.editFeePopup .popup-body {
  padding: 0;
}
.editFeePopup .border-bottom {
  margin: 20px 0;
}
.editFeePopup .multidivs {
  padding: 0;
  padding-bottom: 20px;
}
.editFeePopup .popup-body {
  overflow: auto;
  height: 100%;
  max-height: calc(100% - 85px);
}
.form-grp .checkmark-label {
  border: none;
  padding: 0;
  padding-bottom: 10px;
}
