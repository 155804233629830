.back-btn {
  background: transparent;
  border: none;
  outline: none;
  /* align-items: flex-start; */
  align-items: center;
  gap: 10px;
  display: flex;
  font-family: "ns-semibold";
  color: var(--grey);
  font-size: 12px;
  cursor: pointer;
}
.side-links {
  display: flex;
  gap: 10px;
}
.icon-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  justify-content: center;
}
.danger-btn-outline.icon-btn:hover {
  background: none;
  color: var(--red);
}
.green-btn-outline.icon-btn {
  background: var(--green);
  color: var(--white);
}
.green-btn-outline.icon-btn:hover {
  background: var(--green);
  color: var(--white);
}
.viewclub-container {
  width: 100%;
  background: var(--bg-color);
  display: flex;
  /* align-items: center; */
  justify-content: center;
  overflow: auto;
  overflow-y: hidden;
  padding: 0 20px;
  height: calc(100vh - 120px);
}
.viewclub-wrapper {
  width: 100%;
  /* height: 100%; */
  border-radius: 10px;
  border: 1px solid var(--border);
  background: var(--white);
}
.view-club-detail .form-group {
  padding-top: 0;
}
.common-container {
  max-width: 800px;
  width: 100%;
  padding: 30px 0;
}
.table-container {
  width: 100%;
  /* padding: 20px; */
  margin: auto;
  background: var(--white);
  /* min-height: calc(100vh - 210px); */
  overflow-x: hidden;
max-height: calc(100vh - 310px);
  margin: 0;
  /* width: auto; */
  border-bottom: none;
}
.view-club-member .viewclub-wrapper {
  background: none;
  border: none;
}
.view-club-member .filter-bar {
  padding: 10px 0;
}
/* .view-club-member .feedback-table tr th {
    padding: 9px 15px;
}
.view-club-member .feedback-table tr td {
    padding: 9px 15px;
} */
.view-club-member .form-footer {
  background-color: var(--white);
}
.chat-msgsend-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-top: 1px solid var(--border);
}
.chat-msgsend-container .chat-input {
  border: none;
  width: calc(100% - 65px);
  padding: 5px;
  background-color: var(--white);
  /* font-size: 16px;
    font-family: ns-regular; */
}
.chat-msgsend-container .chat-input:focus {
  outline: none;
}
.chat-msgsend-container .send-outer {
  background: var(--blue);
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 0;
}
.chat-comment-container {
  padding: 20px;
  max-height: calc(100vh - 280px);
    overflow: auto;
}
.chat-comment-left {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.chat-comment-left-top {
  display: flex;
  gap: 10px;
}
.chat-comment-left-top img,.chat-comment-right-top img {
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
}
.chat-comment-left-right p {
  background: #fef6d6;
  border-radius: 0px 10px 10px 10px;
  font-family: ns-regular;
  font-size: 13px;
  text-transform: capitalize;
  color: var(--dark-grey);
  max-width: 600px;
  width: 100%;
  padding: 10px 20px;
}
.chat-comment-left-right span {
    display: inline-block;
    font-family: "ns-regular";
    font-size: 10px;
    line-height: 14px;
    color: #999999;
    margin-top: 5px;
}
.chat-comment-right {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}
.chat-comment-right-top {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
}
.chat-comment-right-top p {
    background: var(--border);
    border-radius: 10px 0px 10px 10px;
    font-family: 'ns-regular';
    font-size: 13px;
    text-transform: capitalize;
    color: var(--dark-grey);
    max-width: 600px;
    width: 100%;
    padding: 10px 20px;
  }
  .chat-comment-right-top span {
    display: inline-block;
    font-family: "ns-regular";
    font-size: 10px;
    line-height: 14px;
    color: #999999;
    margin-top: 5px;
    position: absolute;
    right: 0;
}
.chat-comment-right-left{
    position: relative;
}
 
