.createfee .extra-margin {
  margin-top: 200px;
}

.createfee {
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 110px);
  align-items: center;
}

.createfee.show {
  display: flex !important;
}

.createfee .checkmark-label {
  font-size: 13px;
  padding: 0;
  padding-top: 12px;
  border: none;
}

.createfee .checkmark-label .checkmarkspan {
  margin-right: 5px;
}

.multidivs {
  padding: 0 25px;
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.multidivs-content {
  width: 45%;
}

.multidivs.with_3_sections .multidivs-content {
  width: 29%;
}

.form-footer.multibuttons {
  display: flex;
  justify-content: space-between;
}

.border-bottom {
  margin: 30px 25px 5px;
  border-bottom: 1px solid var(--border);
}
.addButtonAlignment {
  min-height: 64px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
  justify-content: flex-end;
}