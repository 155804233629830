.reject-popup {
  max-width: 420px;
  min-height: 350px;
}
.leave-comment {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.form-group-comment {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.form-group-comment textarea{
    padding: 10px;
    border: none;
    background: var(--bg-color);
    border-radius: 10px;
}
