.mail-box-outer{
    padding: 20px;
    margin-top: 20px;
    width: 100%;
    height: calc(100vh - 140px);
    max-width: calc(100% - 40px);
    margin-left: 20px;
    background: var(--white);
    overflow: auto;
}
.mail-details .form-group{
    width: 100%;
    padding: 0;
}
.mail-details .create-input{
    width: 90%;
}
.mail-details .selected-fee{
    max-width: 50%;
}
.mail-details .selected-fee .popup-dropdown{
    width: 90%;
}
.mail-details .primary-btn-a{
    width: 160px;
}
.mail-details .btn-grp{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.edit-mail-container{
    width: 100%;
    overflow: auto;
    height: calc(100% - 260px);
    border: 1px solid var(--border);
}
.edit-email-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.success-btn {
    width: fit-content;
    outline: none;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 13px;
    font-family: "ns-regular";
    line-height: 120%;
    color: var(--white);
    cursor: pointer;
    background: var(--green);
    text-transform: capitalize;
}

.test-mail{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.test-mail .create-input{
    padding: 8px;
    width: 100%;
}
.test-mail .primary-btn-a{
    width: 160px;
}