.course-container .header-bottom{
    display: flex;
    height: fit-content;
    flex-direction: column;
    background: var(--white);
}
.course-container .club-header{
    height:  fit-content;
    max-height: fit-content;
}
.course-detail {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.course-container .header-top{
    height: 200px;
}
.course-detail.content{
    flex-direction: column;
    gap: 20px;
}
.course-detail.content p{
    font-size: 12px;
    font-family: 'ns-semibold';
    color: var(--black);
}

.primary-outline-btn-a{
    font-size: 13px;
    font-family: 'ns-semibold';
    color: var(--blue);
    border-radius: 300px;
    background: var(--white);
    border: 1px solid var(--blue);
    padding: 5px 10px;
    transition: 0.4s;
    cursor: pointer;
}
.primary-outline-btn-a:hover{
    background: var(--blue);
    color: var(--white);
}
.course-container .border{
    border: 1px solid var(--border);
    background: var(--border);
}

.course-table{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content:flex-start;
}

.course-content{
    width: 15%;
    display: flex;
    font-family: 'ns-semibold';
    font-size: 12px;
    flex-direction: column;
}
.course-content .heading{
    color: var(--grey);
}
.course-body{
    display: flex;
    flex-direction: column;
}
.course-body .feedback-container{
    margin: 0;
    width: 100%;
    background: var(--white);
}
.course-body-header{
    font-family: 'ns-semibold';
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}