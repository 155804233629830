.goback-poup-poup {
    max-width: 420px;
    max-height: 230px;
}

.popup-footer .btn-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.popup-footer .btn-group .danger-btn {
    width: auto;
    text-transform: uppercase;
    font-size: 14px;
}

.popup-footer .btn-group .dark-btn-outline {
    text-transform: uppercase;
    font-size: 14px;
}