.upload-btn-wrapper {
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  width: 100%;
  padding: 6px 12px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
.upload-inner {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
}
.upload-btn-wrapper img {
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
}
.upload-btn-wrapper img:hover {
  background-color: var(--bg-color);
}
.upload-btn {
  background: var(--border);
  border-radius: 20px;
  color: var(--black);
  font-family: "ns-semibold";
  font-size: 13px;
  border: none;
  outline: none;
  padding: 8px;
  cursor: pointer;
}
.file-name {
  font-size: 13px;
  color: var(--green);
  line-height: 120%;
  font-family: "ns-regular";
}
.interview .dropdown {
  max-width: 100%;
}
.interview-container {
  min-height: 650px;
}
.interview-container .form-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
