.side-menu {
  position: fixed;
  top: 50px;
  left: 0;
  height: 100%;
  overflow-y: auto;
  background: var(--white);
  scrollbar-width: 1px;
  border-right: 2px solid var(--border);
  transition: 0.4s;
  z-index: 2000;
  width: 60px;
  overflow: hidden;
}
.side-menu.active {
  width: 240px;
  overflow: auto;
  overflow-x: hidden;
  filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.1));
}
.sidenav-list {
  list-style: none;
  width: 240px;
}
.sidenav-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0px !important;
  gap: 5px;
  cursor: pointer;
  transition: 0.5s;
  background: var(--white);
  z-index: 100;
  overflow: hidden;
  text-decoration: none;
}
.sidenav-list-item.active .main-menu p {
  color: var(--blue);
}
.sidenav-list-item.active .main-menu {
  background: var(--light-blue);
}
.sidenav-list-item.active .img-hover img:first-child {
  display: none;
}
.sidenav-list-item.active .img-hover img:last-child {
  display: block;
}
.main-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  overflow-x: hidden;
  padding: 20px;
}
.main-menu:hover {
  background: var(--light-blue);
}

.sidenav-list-item .main-menu p {
  display: block;
  font-size: 14px;
  font-family: "ns-regular";
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;
  text-decoration: none;
  color: var(--black);
}
.side-menu.active .sidenav-list-item .main-menu p {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.sidenav-list-item:hover .main-menu p {
  color: var(--blue);
}
.img-hover {
  display: flex;
}

.img-hover img:last-child {
  display: none;
}
.sidenav-list-item:hover .img-hover img:first-child {
  display: none;
}
.sidenav-list-item:hover .img-hover img:last-child {
  display: block;
}

ul.sub-menu {
  width: 100%;
  height: 0px;
  list-style: none;
  transition: height 0.2s;
}
/* .side-menu:hover ul.sub-menu{
  width: 100%;
  height: 0;
  overflow: hidden;
} */
.sub-menu-item {
  text-decoration: none;
  color: var(--black);
  display: flex;
  flex-direction: row;
  padding: 15px 30px;
  gap: 7px;
  align-items: center;
}
.sub-menu-item:hover{
  color: var(--blue);
}
.sub-menu-item p {
  /* visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.5s, opacity 0.5s linear; */
  font-family: "ns-regular";
  font-size: 13px;
}
.side-menu:hover .sub-menu-item p {
  /* visibility: visible;
  opacity: 1;  
  transition-delay: 0.5s; */
}
.sidemenu-togger {
  position: absolute;
  right: 20px;
}
.menuToggler.active .sub-menu {
  height: auto;
  width: 100%;
}
.sidemenu-togger {
  transition: 0.1s;
}
.menuToggler.active .sidemenu-togger {
  transform: rotate(180deg);
}
.menuToggler.active .sub-menu {
  background: var(--bg-color);
}
.menuToggler.active .sub-menu .sub-menu-item:hover {
  background: var(--light-blue);
}

.sub-menu.active{
  background: var(--blue);
  
}