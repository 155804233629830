.sliderDownTable{
    height: 0;
    overflow: hidden;
    transition: 0.4s ease-in; 
}

.sliderDownTable.active{
    height: 100%;
    transition: 0.4s ease-in;
}

.sliderDownTable table{
    padding: 10px;
    width: 100%;
}

.flexlistWrapper{
    height: calc(100vh - 180px) !important;
}

.sliderDownTD{
    padding: 0 !important;
    border: none;
}

.flextable-table{
    border: 2px solid var(--bg-color);
    border-bottom: 0;
    background: var(--white);
}

.flextable-table thead{
    position: static;
}

.textBlue{
    color: var(--primary-5) !important;
    width: 100px;
    cursor: pointer;
}

.flextable-table tr th{
    background: var(--grey-3);
    color: var(--black-5);
    
}