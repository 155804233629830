.expenceDashboardOuter {
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    height: calc(100vh - 100px)
}

.expenceGraphsOuter {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--border);
    display: flex;
    background: var(--white);
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
}

.expenceGraphOuter .filter-bar {
    padding: 0;
    padding-bottom: 20px;
}

.expenceGraphOuter {
    width: 49%;
    height: 100%;
}

.expenceDropdownDropdown {
    cursor: pointer;
    background-image: url(https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    position: relative;
    width: 100%;
    padding: 15px 10px;
    border-bottom: 1px solid var(--border);
}

.dropdownBox {
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    max-height: 200px;
    left: 0;
    width: 100%;
    top: 100%;
    border: 1px solid var(--border);
    position: absolute;
    background: var(--white);
}

.dropdownBox.active {
    visibility: visible;
    opacity: 1;
}

.dropdownItem {

    cursor: pointer;
    font-size: 14px;
    padding: 10px;
    color: var(--black);
    font-family: 'ns-regular';
    transition: 0.3s;
    width: 100%;
}

.dropdownItem:hover {
    color: var(--blue);
    background: var(--light-blue);
}

.selectedItem {
    font-size: 16px;
    font-family: 'ns-semibold';
}

.expenceTableOuter {
    border: 1px solid var(--border);
    border-radius: 10px;
    width: 100%;
}

.expenceTable {
    width: 100%;
}

.expenceTable thead {
    width: 100%;
    box-shadow: 0px 4px 2px rgb(0 0 0 / 2%);
    position: sticky;
    top: 0;
    z-index: 1000;
    background: var(--white);
}

.expenceTable th {
    text-align: left;
    font-size: 14px;
    color: var(--grey);
    padding: 15px 20px;
    font-family: 'ns-semibold';
}

.expenceTable td {
    background: var(--white);
    border-bottom: 1px solid var(--border);
    text-align: left;
    font-size: 14px;
    color: var(--black);
    padding: 15px 20px;
    font-family: 'ns-semibold';
}

.expenceTable tr:last-child {
    border: none;
}

.tableFooter {
    gap: 10px;
    padding: 20px;
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.tableFooter .primary-text {
    cursor: pointer;
}

.expencePieChart {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.chartDetailsOuter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
    width: 100%;
    padding: 15px 20px;
    background: var(--bg-color);
}

.chartDetails {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chartDetails .name,
.chartDetails .percent {
    font-size: 13px;
    line-height: 120%;

    font-family: 'ns-semibold';
}

.progressbarOuter {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    width: 50%;
}

.progressBar {
    position: relative;
    width: 100%;
    height: 5px;
    background: transparent;
}

.progress {
    border-radius: 300px;
    right: 0;
    position: absolute;
    height: 100%;
    background: var(--green);
}

.dueFeeListOuter{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    height: calc(100vh - 50px);
}

.feeTable{
    width: 100%;
}

.feeTableOuter{
    width: 100%;
}

.feeDueTableContainer{
    height:calc(100vh - 150px);
}

.searchInput:focus{
    outline: none;
    border: none;
}