.payment-popup {
  width: 100%;
  height: 100%;
  max-width: 420px;
  max-height: 375px;
  padding: 0;
  padding-bottom: 20px;
}
.payment-popup .popup-header {
  padding-bottom: 0px;
}
.payment-popup .popup-body {
  padding: 0 20px;
}
.payment-popup .popup-footer {
  padding: 0 20px;
}
.payment-popup .text {
  font-size: 13px;
  font-family: "ns-regular";
  line-height: 150%;
  color: var(--black);
  margin-top: 10px;
}
.payment-popup .btn-group {
  width: 100%;
}
.payment-popup .primary-btn {
  width: 100%;
  font-family: "ns-regular";
}
