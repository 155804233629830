.approval-drpdown {
    width: 180px;
  }
  
  .selection {
  
    background: var(--bg-color);
  }
  
  .selection td {
    padding: 10px;
  }
  
  .selection-table {
    width: 100%;
  }
  
  .selection-table td {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    gap: 10px;
    text-align: center;
    padding: 0;
    border-bottom: none !important;
  }
  
  .selection-table td p {
    font-size: 13px;
    line-height: 120%;
    font-family: "ns-regular";
  }
  
  .selection-table td p b {
    font-family: "ns-bold";
  }
  
  .selection-table img {
    padding: 10px;
    background: var(--white);
    border-radius: 50%;
    position: absolute;
    right: 54px;
    cursor: pointer;
  }
  
  /* .applicantList {
    height: calc(100vh - 330px);
  } */
  
  .studentDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: auto;
  }
  
  .fixedTableDesign {
    width: max-content;
  }
  
  .tablew100{
    max-width: 100%;
    width: 100%;
  }
  
  .fixedTableDesign tr th:first-child {
    background: var(--black-5);
    position: sticky;
    z-index: 1;
    left: 0;
    width: 10px;
  }
  
  .fixedTableDesign tr td:first-child {
    position: sticky;
    background: var(--white);
    z-index: 1;
    left: 0;
    width: 10px;
  }
  
  .fixedTableDesign tr td:nth-child(2){
    position: sticky;
    background: var(--white);
    z-index: 1;
    left: 41px;
    /* box-shadow: 0 0 5px rgba(0,0,0,0.2); */
    clip-path: inset(0px -15px 0px 0px);
  }
  .fixedTableDesign tr th:nth-child(2) {
    position: sticky;
    background: var(--black-5);
    z-index: 1;
    left: 41px;
  }
  
  .fixedTableDesign tr td:last-child{
    position: sticky;
    background: var(--white);
    z-index: 1;
    right: 0;
    width: 20px;
    border-left: 1px solid var(--border);
    /* box-shadow: 0px 6px 5px rgb(0 0 0 / 20%); */
  }
  .fixedTableDesign tr th:last-child {
    position: sticky;
    background: var(--black-5);
    z-index: 1;
    right: 0;
   
  }
  /* 
  .fixedTableDesign th, .fixedTableDesign td{
    width: max-content;
  } */
  
  .filterTableHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  
  .filterImg {
    display: flex;
    gap: 2px;
    flex-direction: column;
  }
  
  .filterImg img {
    filter: contrast(0.2);
  }
  
  .filterImg img.active {
    filter:contrast(1);
  }
  
  .rollNo{
    color: var(--grey);
    font-size: 12px;
  }
  
  .fixedTableDesign tr th:first-child .checkmarkspan{ 
    border: 1px solid var(--white);
  } 
  
  .applicantDropdown{
    right: 55px;
  }
  
  .yellow-text{
    color: var(--disabled-primary);
  }
  
  
  .light-grey-text{
    color: var(--grey);
  }
  
  
  .dark-grey-text{
    color: var(--dark-grey);
  }
  .counsellorName{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
  }
  
  .counsellorName p{
    width: max-content;
  }
  
  /** tooltip **/
  
  .feedback-table tr td.Tabletooltip p {
    width: 140px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    overflow: hidden;
    display: -webkit-box;
    height: 19px;
    -webkit-box-orient: vertical;
    position: relative;
  }
  
  .feedback-table tr td.Tabletooltip > em {
    position: absolute;
    top: 0;
    right: 0px;
    background: #ccc;
    padding: 5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    cursor: pointer;
  }
  
  .feedback-table tr td.Tabletooltip > em:hover {}
  
  .feedback-table tr td.Tabletooltip label.Insidetooltip {
    position: absolute;
    bottom: 45px;
    right: 0;
    transform: translateX(0);
    width: max-content;
    background: #004e9a;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    max-width: 250px;
    min-width: 100px;
    opacity: 0;
    transition: 0.5s ease-in-out;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    display:
    -webkit-box;
    max-height: 98px;
    -webkit-box-orient: vertical;
  }
  
  label.Insidetooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: #004e9a transparent transparent transparent;
  }
  
  .feedback-table tr td.Tabletooltip:hover label.Insidetooltip {
    opacity: 1;
  }

  .main-body.fullWidth{
    padding-left: 0;
  }


  .SecondaryButton {
    background: none;
    border-radius: 20px;
    border: 1px solid #229e6d;
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: 14px;
    font-weight: 300;
    /* letter-spacing: 1px; */
    cursor: pointer;
    color: #229e6d;
    }
.SecondaryButton:hover {
    opacity: 0.80;
    /* text-decoration: underline; */
    background-color: #229e6d;
    color: #FFFFFF;
    }
  .PrimaryButton {
    background: none;
    border-radius: 20px;
    border: 1px solid #004e9a;
    padding: 5px 15px;
    background:#004e9a;
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: 14px;
    font-weight: 300;
    /* letter-spacing: 1px; */
    cursor: pointer;
    color: #fff;
    }
.PrimaryButton:hover {
    opacity: 0.80;
    /* text-decoration: underline; */
    background-color: #004e9a;
    color: #FFFFFF;
    }

/* .BC-Gov-SecondaryButton:active {
    opacity: 1;
    } */

    .designV2.feedback-table tr:nth-child(odd) td{
      background: #F5F5F5;
    }