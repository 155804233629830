.newDashboardContainer {
    width: 100%;
    max-height: calc(100vh - 115px);
    padding: 20px;
    overflow: auto;
}

.headerBoxes {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboardFilters {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.headerBoxes .box {
    cursor: pointer;
    position: relative;
    width: 15%;
    padding: 15px 10px;
    background: var(--white);
    border: 1px solid var(--border);
    border-radius: 5px;
}

.headerBoxes .box p.value {
    font-size: 20px;
    line-height: 120%;
    font-family: 'ns-bold';
}

.headerBoxes .box p.valueItem {
    margin-top: 10px;
    font-size: 14px;
    line-height: 120%;
    font-family: 'ns-regular';
}

.headerBoxes .box img {
    position: absolute;
    right: 5px;
    top: 5px;
}

.danger-text {
    color: var(--red);
}

.green-text {
    color: var(--green);
}

.studentStatusOuter {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 10px 0;
    justify-content: space-between;
}

.studentStatusBox {
    border-radius: 5px;
    border: 1px solid var(--border);
    background: var(--white);
}

.studentStatusBox:first-child {
    width: 315px;
    min-height: 453px;
}

.studentStatusBox:last-child {
    width: calc(100% - 330px)
}

.header {
    padding: 15px;
    border-bottom: 1px solid var(--border);
}

.header .heading {
    font-size: 14px;
    line-height: 120%;
    font-family: 'ns-regular';

}

.studentStatusBox .body {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    width: 100%;
}

.attendaceBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--border);
    transition: 0.3s;
}

.attendaceBox:hover {
    background: var(--bg-color);
}

.attendaceBox .attendanceHeader {
    font-size: 14px;
    line-height: 120%;
    font-family: 'ns-regular';
    align-items: center;
    gap: 5px;
    display: flex;
    justify-content: flex-start;
}

.attendaceBox .attendanceBody {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.attendaceBox .attendanceBody .value {
    font-size: 24px;
    line-height: 120%;
    font-family: 'ns-bold';
}

.attendaceBox a {
    font-size: 13px;
    font-family: 'ns-regular';
    color: var(--black);
}

.blueBox {
    height: 5px;
    width: 5px;
    background: var(--blue);
    border-radius: 1px;
}

.greenBox {
    height: 5px;
    width: 5px;
    background: var(--green);
    border-radius: 1px;
}

.dangerBox {
    height: 5px;
    width: 5px;
    background: var(--red);
    border-radius: 1px;
}

.paymentBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    border: 1px solid var(--border);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
}

.paymentBox .upperBody {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.paymentBox .lowerBody {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;

}

.paymentBox .paymentDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.paymentDetails .value {
    font-size: 20px;
    font-family: 'ns-bold';
    color: var(--green);
    line-height: 120%;
}

.paymentDetails .text {
    font-family: 14px;
    font-family: 'ns-regular';
}

.paymentDetails .text span {
    font-family: 'ns-bold';
}

.studentStatusBox .dashboardPayment {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.paymentBoxOuter {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
}

.detailsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    padding: 10px;
    background: #f5f5f5;
    border-radius: 5px;
}

.lowerBody .details {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.lowerBody .details p {
    width: 33%;
    text-align: center;
}


.lowerBody .details .content {
    font-size: 14px;
    width: max-content;
    font-family: 'ns-regular';
    line-height: 120%;
    text-align: left;
}

.lowerBody .details .value {
    font-size: 14px;
    text-align: right;
    width: max-content;
    font-family: 'ns-bold';
}

.newDashboardChart {
    border-radius: 5px;
    background: var(--white);
    border: 1px solid var(--border);
}

.customDropdownOuter {
    cursor: pointer;
    font-family: 'ns-semibold';
    font-size: 13px;
    padding: 10px;
    border: 1px solid var(--border);
    border-radius: 5px;
    position: relative;
    width: 250px;
    background-image: url("https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
}

.dropdownItems {
    top: 107%;
    background: var(--white);
    list-style: none;
    position: absolute;
    width: 100%;
    z-index: 1;
    left: 0;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;

}

.customDropdownOuter.active .dropdownItems {
    opacity: 1;
    visibility: visible;

}


.dropdownItem {
    padding: 10px;
    cursor: pointer;
    transition: 0.4s;
}

.dropdownItem:hover {
    background: var(--bg-color);
}

.newDashboardChart .header {
    font-size: 14px;
    font-family: 'ns-semibold';
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 5px 15px;
}

.switchTabsBtns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.switchTabsBtns button {
    background-color: transparent;
    border: none;
    color: var(--grey);
    font-family: 'ns-semibold';
    font-size: 16px;
    cursor: pointer;
    padding: 0;
}

.switchTabsBtns button.active {
    color: var(--blue);
}

.switchTabs {
    display: none;
}

.switchTabs.active {
    display: block;
}

.newProgramSummary {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    height: calc(100vh - 100px);
}

.programSummaryHeader {
    width: 100%;
    background: var(--black);
    border-radius: 10px;
    padding: 10px;
}

.programSummaryHeader p {
    color: var(--white);
    font-size: 16px;
}

.programSummaryHeader p span {
    font-size: 18px;
    font-family: 'ns-bold';
}

.programSummaryContainerOuter {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
}

.programSummaryBox {
    width: 32%;
    max-height: calc(100vh - 210px);
    overflow: auto;
    padding: 15px;
    border-radius: 5px;
    background: var(--white);
    border: 1px solid var(--grey-3);
}

.programSummaryList {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
}

.programSummaryListItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    background: var(--grey-2);
}

.programSummaryListItem p {
    font-size: 13px;
}

.programSummaryListItem p:last-child {
    font-family: 'ns-bold';
}

.programSummaryBoxHeader {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    margin-bottom: 10px;
}

.programSummaryBoxHeaderRight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.programSummaryBoxHeaderRight p {
    font-size: 14px;
}

.programSummaryBoxHeaderRight p.success-text {
    font-size: 20px;
    font-family: 'ns-bold';
}

.feeHeaderBoxes {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 20px 20px 0;
    gap: 20px;
}

.feeHeaderBox {
    border: 1px solid var(--border);
    background: var(--white);
    display: flex;
    flex-direction: column;
    padding: 5px 20px;
    border-radius: 5px;
}

.feeHeaderBox .successText {
    color: var(--success-5);
    font-size: 18px;
    font-family: 'ns-bold';
}

.feeHeaderBox .heading {
    font-size: 13px;
    font-family: 'ns-semibold';
    color: var(--black-3);
}

.mentorContainerOuter {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    max-height: calc(100vh - 115px);
    padding: 20px;
    overflow: auto;
}

.mentorTable {
    width: 100%;
}

.mentorTable tr th,
.mentorTable td {
    padding: 15px 10px;
    font-family: "ns-semibold";
    text-align: left;
}

.mentorTable tr th {
    color: var(--white);
    background: var(--black-5);
    text-transform: capitalize;
}

.mentorTable tr td {
    color: var(--black);
    font-size: 14px;
    border-bottom: 2px solid var(--bg-color);
    position: relative;
}

.mentorTable thead {
    box-shadow: 0px 4px 2px rgb(0 0 0 / 2%);
    position: sticky;
    top: 0px;
    z-index: 1000;
}

.mentorTable tbody {
    overflow: auto;
}

.mentorTable tr {
    border-bottom: 1px solid var(--grey);
    position: relative;
}

.mentorTableContainer{
    width: 100%;
    border: 1px solid var(--border);
    border-radius: 5px;
    overflow: auto;
    background: var(--white);
    height: max-content;
    max-height: 40vh;
}

.primaryBtnOutlineSmall{
    border: 1px solid var(--primary-6);
    color: var(--primary-6);
    background: transparent;
    outline: none;
    font-family: 'ns-semibold';
    font-size: 11px;
    cursor: pointer;
    padding: 4px 12px;
    border-radius: 100px;
}
.noRotate img{
    height: auto;
    width: auto;
    animation: none;
    display: block;
}

.boxAutomationContainer{
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: calc(100vh - 160px);
}

.boxesTableOuter{
    background: var(--white);
    width: 46%;
    overflow: auto;
    border: 1px solid var(--border);
    border-radius: 10px;
    height: 100%;
    
}

.boxesTable{
    width: 100%;
    text-align: left;
}

.boxesTable tr th,
.boxesTable td {
    padding: 15px 10px;
    font-family: "ns-semibold";
    text-align: left;
}

.boxesTable tr th {
    color: var(--white);
    background: var(--black-5);
    text-transform: capitalize;
}

.boxesTable tr td {
    color: var(--black);
    font-size: 14px;
    border-bottom: 2px solid var(--bg-color);
    position: relative;
}

.boxesTable thead {
    box-shadow: 0px 4px 2px rgb(0 0 0 / 2%);
    position: sticky;
    top: 0px;
    z-index: 1000;
}

.boxesTable tbody {
    overflow: auto;
}

.boxesTable tr {
    border-bottom: 1px solid var(--grey);
    position: relative;
}

.vendorTableOuter{
    width: 100%;
    border: 1px solid var(--border);
    background: var(--white);
    border-radius: 10px;
    overflow: auto;
    height: 100%;
}

.switchTabs .primary-btn{
    padding: 8px 16px;
    font-size: 13px;
    font-family: 'ns-regular';
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: max-content;
}

.page-header.flex-end{
    gap: 10px;
}

.highlight{
    font-size: 14px;
  color: var(--blue) !important;
    cursor: pointer;
}