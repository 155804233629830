.delete-poup {
    width: 420px;
    height: 230px;
    padding: 20px;
  }
  .delete-poup .popup-header{
    padding: 0;
  }
  .delete-poup .popup-heading{
    padding-bottom: 10px;
  }
  .delete-poup .popup-footer{
    padding: 0;
  }
  .delete-poup .popup-header-body{
    padding: 20px 0 0;
  }
  .popup-footer .btn-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  .popup-footer .btn-group .danger-btn {
    width: auto;
    text-transform: uppercase;
    font-size: 14px;
  }
  .popup-footer .btn-group .dark-btn-outline {
    text-transform: uppercase;
    font-size: 14px;
  }
  