.boxVendorPopup .popup-container{
    width: 420px;
    min-height: 300px;
    padding: 0;
}

.boxVendorPopup .popup-body{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.boxVendorPopup .fee-option{
    width: 100%;
}

.boxVendorPopup .form-group{
    width: 100%;
    padding: 0;
    padding-bottom: 10px;
}

.boxVendorPopup .primary-btn-a{
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 14px;
}

.boxVendorPopup .popup-footer{
    padding: 20px;
    padding-top: 0px;
}