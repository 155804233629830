/* .chatOuterr {
  position: relative;
}
.chatOuter {
  float: left;
  width: 100%;
  padding: 0 20px;
  min-height: 390px;
  font-family: "ns-regular";
  font-size: 14px;
  max-height: calc(100vh - 350px);
  overflow: auto;
}
.chatLeft{
    display: flex;
    gap: 10xp;
    margin-bottom: 20px;
    align-items: flex-end;
}
.chatLeft img{
    height: 32px;
    width: 32px;
    border-radius: 50%;
}
.chatRight{
    display: flex;
    width: 100%;
    gap: 10px;
    flex-direction: row-reverse;
    align-items: flex-end;
    margin-bottom: 20px;
}
.chatRight img{
    height: 32px;
    width: 32px;
    border-radius: 50%;
}

.chatLeftInner {
  background: #f3f3f3;
  padding: 10px;
  border-radius: 10px;
  width: auto;
  display: inline-block;
  border: 1px solid #EEEEEE;
  border-bottom-left-radius: 0;
}


.chatRightInner {
  background: #E2EBF4;
  padding: 10px;
  border-radius: 10px;
  width: 90%;
  display: inline-block;
  float: right;
  border-bottom-right-radius: 0;
 
}

.chatLeftOutr {
  float: left;
  width: 100%;
}

.chatHeading {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: bold;
}

label.chatTime {
  float: right;
  font-size: 10px;
  color: #bbb;
}

p.chatMsg {
  font-size: 14px;
  margin-bottom: 5px;
}

.chatMsgOutr {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.popup-form-outer.scrollbar.active {
}

.popup-form-outer.scrollbar.active.hide {
  display: none;
}

.chatReply {
  float: left;
  width: 100%;
  padding: 0 20px;
  position: absolute;
  display: inline-block;
  left: 0;
  bottom: 0;
}

.chatReply textarea {
  padding: 10px;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  font-size: 13px;
  font-family: "ns-semibold";
  width: 100%;
} */

.adminAgendaPopup{
  max-height: fit-content;
  height: calc(100% - 100px);
}

.adminAgendaPopup .chatOuter{
  max-height: fit-content;
}

.chatOuterr{ position: relative;}
.chatOuter { 
    float: left;
    width: 100%;
    padding: 0 20px;
    min-height: 390px;
    font-family: "ns-regular";
    font-size: 14px;
    max-height: calc(100vh - 420px);
    overflow: auto;
}

.chatLeft {background: #f3f3f3;padding: 10px;border-radius: 20px;width: auto;display: inline-block;margin-bottom: 20px;border: 1px solid #ebebeb;border-bottom-left-radius: 0;}

.chatRight {
    background: #d0ffdf;
    padding: 10px;
    border-radius: 20px;
    width: 90%;
    display: inline-block;
    float: right;
    border-bottom-right-radius: 0;
    margin-bottom: 20px;
}

.chatLeftOutr {
    float: left;
    width: 100%;
}

.chatHeading {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
}

label.chatTime {
    float: right;
    font-size: 10px;
    color: #bbb;
}

p.chatMsg {
    font-size: 14px;
    margin-bottom: 5px;
}

.chatMsgOutr {
    display: flex;
    align-items: center;
    gap: 10px;
}

.popup-form-outer.scrollbar.active {}

.popup-form-outer.scrollbar.active.hide {
    display: none;
}

.chatReply {
    float: left;
    width: 100%;
    padding: 0 20px;
    position: absolute;
    display: inline-block;
    left: 0;
    bottom: 0;
}

.chatReply textarea {
    padding: 10px;
    border: 1px solid var(--light-grey);
    border-radius: 5px;
    font-size: 13px;
    font-family: "ns-semibold";
    width: 100%;
}


.pointer{
  cursor: pointer;
}

.chatOuterr .chatReply .sun-editor .se-resizing-bar.sun-editor-common {
  display: none;
}
.chatOuterr .chatReply .sun-editor {
  margin-bottom: 10px;
}