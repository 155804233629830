.interviewSettingsOuter {
    display: flex;
    width: calc(100% - 60px);
    align-items: flex-start;
    justify-content: flex-start;
    height: calc(100vh - 50px);
    margin-top: 0;
    margin-left: 60px;
  }
  
  .interviewSettingsMenu {
    width: 200px;
    height: 100%;
    border-right: 1px solid var(--grey-3);
  }
  
  .interviewSettingMenuOption {
    padding: 15px;
    background: var(--white);
    color: var(--black-5);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .interviewSettingMenuOption.active{
    background: var(--black-5);
    color: var(--white);
  }
  
  .interviewSettingsContainer {
    width: calc(100% - 200px)
  }
  
  .interviewSettingsContainer .page-header {
    justify-content: center;
  }
  
  .page-toggler {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    color: var(--grey-5);
  }
  
  .page-toggler.active{
    color: var(--primary-5);
  }
  
  .interviewSettingTableContainer {
    width: calc(100% - 40px);
    /* padding: 20px; */
    margin-left: 20px;
    background: var(--white);
    /* height:100%; */
    height: calc(100vh - 150px);
    overflow-x: auto;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  .interviewSettingsTable {
    width: 100%;
  }
  
  .interviewSettingsTable tr th,
  .interviewSettingsTable td {
    padding: 15px 10px;
    font-family: "ns-semibold";
    text-align: left;
  }
  
  .interviewSettingsTable tr th {
    color: var(--white);
    background: var(--black-5);
    text-transform: capitalize;
  }
  
  .interviewSettingsTable tr td {
    color: var(--black);
    font-size: 14px;
    border-bottom: 2px solid var(--bg-color);
    position: relative;
  }
  
  .interviewSettingsTable thead {
    box-shadow: 0px 4px 2px rgb(0 0 0 / 2%);
    position: sticky;
    top: 0px;
    z-index: 1000;
  }
  
  .interviewSettingsTable tbody {
    overflow: auto;
  }
  
  .interviewSettingsTable tr {
    border-bottom: 1px solid var(--grey);
    position: relative;
  }
  
  .interviewsNameContainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    width: 100%;
    padding: 4px;
    background: var(--grey-1);
    border: 1px solid var(--grey-3);
    border-radius: 5px;
  }
  
  .interviewerName {
    padding: 4px 8px;
    background: var(--primary-1);
    color: var(--primary-5);
    border-radius: 5px;
  }
  
  .interviewSettingsContainer .page-header.type-2 {
    justify-content: flex-start;
  }
  
  .breadCrump {
    font-family: 'ns-semibold';
  }
  
  .breadCrump .grey-text {
    font-family: 'ns-semibold';
    color: var(--black-1);
  }
  
  .availableSlotContainer {
    width: 900px;
    overflow: hidden;
    margin: 20px auto 0;
    border: 1px solid var(--grey-3);
    background: var(--white);
    border-radius: 5px;
  }
  
  .availableSlotContainerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: var(--black-5);
  }
  
  .availableSlotContainerHeader .heading {
    font-size: 16px;
    color: var(--white);
  }
  
  .availableSlotContainerHeader .whiteTag {
    background: var(--white);
    border-radius: 5px;
    padding: 5px;
  }
  
  .availableSlotContainerHeader .whiteTag span {
    font-family: 'ns-bold';
  }
  
  .availableSlotContainerBody {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: calc(100vh - 220px);
  }
  
  .availableSlotContainerLeft {
    height: 100%;
    width: 230px;
    border-right: 1px solid var(--grey-3);
    display: flex;
    flex-direction: column;
  }
  
  .interviewer {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 15px;
    transition: 0.3s;
    border-bottom: 1px solid var(--grey-3);
  }
  
  .interviewer img {
    transition: 0.3s;
    visibility: hidden;
  }
  
  .interviewer.active img {
    visibility: visible;
  }
  
  .interviewer.active {
    background: var(--grey-1);
  }
  .interviewer .name{
    font-size: 16px;
    font-family: 'ns-semibold';
  }
  .interviewer.active .name{
    color: var(--primary-6);
  }
  
  .availableSlotContainerCenter .react-calendar {
    width: 100%;
    max-width: 100%;
    border: none;
    padding: 20px;
  }
  
  .availableSlotContainerCenter {
    height: 100%;
    width: 420px;
    border-right: 1px solid var(--grey-3);
  }
  
  .availableSlotContainerRight {
    height: 100%;
    width: 250px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    justify-content: flex-start;
    flex-direction: column;
    padding: 16px;
    overflow: auto;
    position: relative;
  }
  
  .availableSlotContainerCenter .react-calendar__tile {
    max-height: 100%;
    border-radius: 50%;
    font-size: 12px;
    padding: 18px !important;
  }
  
  .availableSlotContainerRight .timeSlot {
    width: 100%;
    background: var(--grey-2);
    border-radius: 5px;
    padding: 8px;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .availableSlotContainerRight .timeSlot.active {
    background: var(--primary-5);
    color: var(--white);
  }
  
  .availableSlotContainerRight .timeSlot p {
    font-family: 'ns-semibold';
    font-size: 14px;
  }
  
  .availableSlotContainerRight .heading {
    font-size: 14px;
    font-family: 'ns-semibold';
  }
  
  .availableSlotContainerRight .footer {
    border: none;
    padding: 0;
    position: fixed;
    bottom: 33px;
    display: flex;
    width: 220px;
    align-items: center;
    justify-content: center;
    padding-bottom: 16px;
  }
  
  .availableSlotContainerRight .footer .primary-outline-btn{
    width: 100%;
  }