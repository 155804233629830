.calculator-header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}
.calculator-header .text-1{
    font-size: 40px;
    font-family: 'ns-bold';
    color: var(--black);
}
.calc-form-outer{
    padding: 20px;
    margin-top: 50px;
    display: flex;
    align-items: flex-start;

}
.form-left .radio-group{
    flex-direction: column;
    gap: 10px;
}
.calculator-outer{
    margin-top: 20px !important;
    margin:auto;
    background: var(--white);
    padding: 20px;
    height: calc(100vh - 100px);
    width: calc(100% - 40px);
}
.calc-form-outer .text-2{
    font-size: 18px;
}

.form-left .radio-group .create-label{
    color: var(--black);
}
.header-content .text-2{
    font-size: 18px;
}
.form-right {
    display: flex;
    flex-direction: column;
    width: 60%;
    border-left: 1px solid var(--light-grey);
    height:100% ;
    padding: 0 20px;
}
.form-left {
    width: 20%;
}
.form-right .form-group label{
    font-family: 'ns-semibold';
    margin-bottom: 5px;
    font-size: 13px;
    color: var(--black);
}
.form-right .form-group label span{
    color: var(--grey);
}
.form-right .form-group input{
    font-family: 'ns-semibold';
    background: #f5f5f5;
    font-size: 13px;
    border: 1px solid transparent;
    outline: none;
    padding: 10px;
    border-radius: 10px;
}
.form-right .form-group input:focus{
    border: 1px solid var(--blue);
}
.calc-form-outer .radiomark::after{
    background: #0099CC;
}
.calc-form-outer .radio-btn:checked ~ .radiomark{
    border-color: #0099CC;
}
.form-right .btn-grp{
    margin-top: 30px;
    width: 100%;
    text-align: right;
    padding-bottom: 30px;
}
.form-right button{
    border: none;
    outline: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    background: #0099CC;
    color: var(--white);
    font-size: 13px;
    font-family: 'ns-semibold';
    text-transform: uppercase;
}
.form-right .form-group, .form-right .form-2-col{
    padding: 0;
}
.form-right .border{
    width: 90%;
    margin-right: 0 !important;
    margin: auto;
   
}

.form-right .cluster .create-input{
    background-color:#f5f5f5;
    border: transparent;
}
.form-right ul.cluster-dropdown{
    max-height: 130px;
    top: 100%;
}