.primary-btn.club-btn {
  position: absolute;
  right: 20px;
}
.page-header.club-header {
  justify-content: center;
}
.newrequest-container{
  width: 100%;
    /* padding: 20px; */
    margin: auto;
    /* margin-left: 20px; */
    background: var(--white);
    height: calc(100vh - 210px);
    /* overflow-x: hidden; */
    overflow-x: auto;
}
.newrequest-table{
  width: 100%;
  text-align: left;
  /* table-layout: fixed; */
}
.newrequest-table tr th,.newrequest-table tr td {
  padding: 15px 30px;
  font-family: "ns-semibold";

}
.newrequest-table tr th {
  color: var(--grey);
  background: var(--white);
}
.newrequest-table tr td {
  color: var(--black);
  font-size: 14px;
  border-bottom: 2px solid var(--bg-color);
}
.newrequest-table thead {
  box-shadow: 0px 4px 2px rgb(0 0 0 / 2%);
  position: sticky;
  top: 0;
  z-index: 1000;
}
.newrequest-table tbody {
  overflow: auto;
}

.newrequest-table tr {
  border-bottom: 1px solid var(--grey);
  position: relative;
}
.danger-btn-outline-blue {
  font-size: 14px;
  font-family: "ns-semibold";
  border: 1px solid var(--blue);
  background: var(--white);
  border-radius: 300px;
  padding: 8px 16px;
  color: var(--blue);
  cursor: pointer;
  transition: all 0.3s;
}
.danger-btn-outline-blue:hover {
  background: var(--blue);
  color: var(--white);
}
.td-size{
   max-width: 200px;
   width: 15%;
}
.display-flex{
  display: flex;
  align-items: center;
  gap:15px;
}
.club-dropdown{
  position: relative;
  
}
.club-action-dropdown{
  top: calc(100% - 10px);
  left:0;
  border: 1px solid var(--scroll-thumb);
  display: none;
}
.club-action-dropdown.active{
  display: block;
}
.club-action-dropdown .dropdown-item:not(:last-child){
  border-bottom: 1px solid var(--scroll-thumb);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

}
ul.action-dropdown.club-action-dropdown::after{
display: none;
}
.club-header-btn{
  position: absolute;
  right: 20px;
  max-height: 32px;
}
.new-request {
  padding: 20px;
}
.club-filter{
  padding: 10px 0;
}
