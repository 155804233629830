.meet-popup {
  max-width: 460px;
  max-height: 320px;
  width: 100%;
  height: auto;
  padding: 0;
}
.meet-popup .popup-heading {
  padding-bottom: 10px;
}
.meet-popup .popup-body .text-4 {
  font-size: 16px;
  text-align: left;
  font-family: "ns-semibold";
  color: var(--dark-grey);
}
.popup-footer {
  width: 100%;
  bottom: 0;
  padding: 0 10px;
  padding-bottom: 10px;
}
.loading-outer {
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.loader {
  width: 25px;
  height: 25px;
  background: var(--border);
}
.loader.active {
  background-color: var(--grey);
}
.meet-popup .danger-btn {
  border-radius: 10px;
  font-family: "ns-semibold";
}
.meet-loader {
  width: 50px;
}


.waiting-popup-text{
  color: var(--black);
  line-height: 150%;
}

.waiting-popup-text span{
  display: block;
  color: var(--black);
  font-family: 'ns-bold';
  line-height: 150%;
}

.icon-bar-wrapper{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.icon-bar-wrapper img{
  max-width: 30px;
}

.meet-popup .popup-footer {
  width: 100%;
  bottom: 0;
  padding: 0 20px;
  padding-bottom: 20px;
}

.border-up{
  position: relative;
  display: block;
  border-bottom: 1px solid var(--border);
  padding-top: 20px;
}

.sos-wrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px;
}

.sos-wrapper button{
  max-width: max-content;
  padding: 8px 16px;
  border-radius: 5px !important;
}