.cluster-popup {
  padding: 0;
}
.cluster-popup .form-group {
  padding: 0;
}
.cluster-popup .popup-heading {
  padding-bottom: 10px;
}
.cluster-popup .popup-footer {
  padding: 0 20px;
  padding-bottom: 20px;
}
