.bankTransfer{
    padding: 0;
    max-width: 420px;
}
.bankTransfer .popup-footer{
    padding: 0;
}

.bankTransfer .green-btn{
    width: 100%;
}

.bankTransfer .bank-details:last-child{
     padding-bottom: 20px;
     border-bottom: 1px solid var(--border);
}

