.setPriorityContainer {
  width: 400px;
  border-radius: 5px;
  height: max-content;
  background: var(--white);
  margin: auto;
}

.setPriorityContainer .popup-heading {
  padding-bottom: 10px;
}

.setPriorityContainer .form-group {
  padding: 0;
  padding-bottom: 10px;
}


.App {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.list-container {
  display: flex;
  font-size: 18px;
  background-color: #eee;
  flex-direction: column;
}

.item-container {
  background-color: #fff;
  border: 1px solid black;
  padding: 25px 70px;
  margin: 15px 50px;
}


.dragableOuterContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.priorityRow {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.priority {
  font-size: 13px;
  font-family: 'ns-semibold';
  background: var(--primary-1);
  color: var(--primary-5);
  border-radius: 5px;
  border: none;
  padding: 6px 7px;
}

.list-container {
  width: 100%;
  background: var(--white);
}

.item-container {
  font-size: 14px;
  font-family: 'ns-semibold';
  width: 100%;
  margin: 0 0 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--grey-3);
}

.reactSelectContainer {
  margin-bottom: 50px;
}

.css-9gakcf-option {
  color: var(--black-5) !important;
  background: transparent !important;
}

.pageTogglerOuter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.page-toggler{
  cursor: pointer;
}

.interviewSettingsTable .action-dropdown{
  right: 60%;
}