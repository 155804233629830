.center-heading{
    justify-content: center;
}
.center-heading .component-back{
    position: absolute;
    left: 0;
}
.raiseRequest{
    margin-bottom: 50px;
}

.raiseRequest form{
    padding: 20px;
}

.customSelect{
    border-color: var(--light-grey);
    border-radius: 5px;
}

.raiseRequest .form-2-col{
    padding: 0;
    align-items: center;
}
.raiseRequest .form-grp{
    width: 100%;
}

.line{
    width: 100%;
    height: 1px;
    background: var(--border);
}

.grey-btn{
    border: 1px solid transparent;
    outline: none;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 13px;
    font-family: 'ns-semibold';
    border-radius: 300px;
}

.containerFooter{
    padding: 15px 20px;
    border-top: 1px solid var(--border);
}
.containerFooter button{
    font-size: 16px;
    padding: 10px 20px;
}

.raiseRequest .upload-btn-wrapper{
    border: none;
    background: var(--bg-color);
}

.keyContainerOuter{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--light-grey);
}
.keyItem{
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: max-content;
    font-size: 14px;
    font-family: 'ns-regular';
    border-radius: 300px;
    padding: 5px 12px;
    background: var(--dark-grey);
}
.keyItem img{
    cursor: pointer;
}

.attachFilesTableOuter{
    overflow: auto;
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--border);
}

.attachFilesTable{
    width: 100%;
}

.attachFilesTable th{
    background: var(--white);
    border-bottom: 1px solid var(--border);
    text-align: left;
    padding: 10px 20px;
    font-family: 'ns-semibold';
    font-size: 14px;
    color: var(--dark-grey);
}

.attachFilesTable td{
    background: var(--white);
    border-bottom: 1px solid var(--border);
    text-align: left;
    padding: 10px 20px;
    font-family: 'ns-semibold';
    font-size: 14px;
    color: var(--dark-grey);
    transition: 0.3s;
}
.attachFilesTable tbody tr:hover td{
    background: var(--bg-color);
    color: var(--black);
}

.attachFilesTable tbody tr:last-child td
{
    border: navajowhite;
}
.attachFilesOuter{
    padding: 10px;
    background: var(--bg-color);
}