/* .dashboard-container{
    margin-top: 20px !important;
    margin: auto;
    width: calc(100% - 40px);
    height: calc(100vh - 160px);
}
.dashboard-items{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
}
.dashboard-item{
    width: 23.5%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding: 20px;
    background: var(--white);
    border-radius: 5px;
    cursor: pointer;
}
.dashboard-content .number{
    font-size: 32px;
    font-family: 'ns-bold';
}
.dashboard-content .type{
    font-size: 16px;
    color: var(--grey);
    font-family: 'ns-regular';
}
.dashboard-item.fee{
    background: var(--light-blue);
}
.dashboard-container .heading{
    font-size: 18px;
    margin-top: 20px !important;
    font-family: 'ns-bold';
} */
.dashboard_wrapper {
    padding: 20px;
}

.dashboard_wrapper .heading-3{
    font-family: 'ns-bold';
    font-size: 32px;
    line-height: 120%;
    color: #000000;;
  }
  
  .dashboard_wrapper .text-3{
    font-family: 'ns-regular';
    font-size: 16px;
    line-height: 120%;
    color: #404040;
  }
  
  .padding_adjust_dashboard{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  
  .dashboard_wrapper_top{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }
  
  .dashboard_section{
    max-width: 310px;
    width: 100%;
    background: #fff;
    border: 1px solid #EDEDED;
    padding: 20px;
    min-height: 150px;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }
  
  .dashboard_wrapper_bottom{
    border-top: 1px solid #DFDFDF;
    padding-top: 25px;
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    gap: 20px;
  }
  
  .dashboard_wrapper_bottom .heading-2{
    font-size: 40px;
    line-height: 120%;
    font-family: 'ns-bold';
    color: #000000;
  }
  
  .dashboard_wrapper_bottom .text-4{
    font-size: 18px;
    line-height: 100%;
    font-family: 'ns-regular';
    color: #101010;
  }
  
  .dashboard_details{
    background: #fff;
    border: 1px solid #EDEDED;
    border-radius: 8px; 
    max-width: 310px;
    width: 100%;
    overflow: hidden;
  }
  
  .dashboard_details_top{
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #DFDFDF;
  }
  
  .dashboard_details_bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .dashboard_details_bottom_data{
    max-width: 50%;
    width: 100%;
    padding: 15px 20px;
  }
  
  .dashboard_details_bottom_data:first-child{
    border-right: 1px solid #DFDFDF;
  }
  
  .text-9{
    font-family: 'ns-regular';
    font-size: 14px;
    line-height: 100%;
    color: var(--grey);
  }
  
  .text-10{
    font-family: 'ns-bold';
    font-size: 16px;
    line-height: 100%;
    
  }
  
  .bottom_line{
    height: 6px;
    display: block;
    position: relative;
    width: 100%;
    background: #229E6D;
  }
  
  .yellow-bg{
    background: #FAD133 !important;
  }
  
  .yellow-bg{
    background: #FAD133 !important;
  }
  
  .blue-bg{
    background: #004E9A !important;
  }
  
  .red-bg{
    background: #D63736 !important;
  }
  
  .black-bg{
    background: #404040 !important;
  } 
  
  .programSummaryContainer {
    width: 700px;
    margin: 20px auto;
    background: var(--white);
    border: 1px solid var(--border);
    border-radius: 10px;
    overflow: auto;
    height: calc(100vh - 140px);
}

.programSummaryContainer thead{
  position: sticky;
  top: 0;
  z-index: 10;
}