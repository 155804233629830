.student-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  gap: 10px;
}

.primary-text {
  font-family: "ns-semibold";
  font-size: 16px;
  line-height: 120%;
  color: var(--blue);
}

.fee-details-table {
  width: 100%;
  background: var(--white);
}

.fee-details-table-outer {
  width: calc(100% - 40px);
  margin: auto;
  background: var(--white);
  overflow-x: auto;
  overflow-y: scroll;
  height: calc(100vh - 210px);
}

.fee-details-table thead {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.fee-details-table tbody {
  background: var(--white);
  padding: 2px;
}

.fee-details-table tr th {
  color: var(--grey);
  background: var(--white);
}

.fee-details-table tr td {
  width: 16%;
  font-size: 14px;
  border-bottom: 2px solid var(--bg-color);
}

.fee-details-table tr {
  border-bottom: 1px solid var(--grey);
  position: relative;
}

.fee-details-table tr th,
.fee-details-table td {
  padding: 15px 30px;
  font-family: "ns-semibold";
  text-align: left;
}

.fee-list.active {
  background: var(--bg-color);
}

.all-details-table {
  margin: auto;
  height: 0px;
  width: 96.5%;
  max-width: 100%;
  border: 2px dashed var(--border);
  border-radius: 10px;
  background: var(--white);
}

.pl-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: auto;
  max-width: 99%;
}

.all-details {
  padding: 20px;
  background: var(--bg-color);
}

.all-details-table thead {
  box-shadow: none;
  border: none;
  border-radius: 10px;
}

.all-details-table th,
.all-details-table td {
  padding: 10px 15px !important;
}

.all-details-table thead th:first-child {
  border-radius: 10px 0 0 0;
}

.all-details-table thead th:last-child {
  border-radius: 0 10px 0 0;
}

/* .text-right {
  text-align: right !important;
} */
/* .fee-details-table .light-btn-a {
  margin: auto;
} */

.light-btn-a.active {
  background: var(--white);
}

.d-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100% !important;
}

.selected-fee {
  width: 100%;
  max-width: 250px;
}

.bg-white {
  background: var(--white);
}

.student-container {
  width: calc(100% - 40px);
  margin: auto;
  margin-top: 20px;
}

.student-info-outer {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid var(--border);
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.student-img {
  border: 1px solid var(--black);
  height: 70px;
  width: 70px;
  image-rendering: -webkit-optimize-contrast;
  border-radius: 50%;
}

.student-inner-right {
  width: auto;
  min-width: fit-content;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.bg-grey {
  background: var(--bg-color);
}

.fee-box {
  display: flex;
  align-items: flex-start;
  width: auto;
  flex-direction: column;
  font-family: "ns-regular";
  font-size: 13px;
  line-height: 120%;
  color: var(--dark-grey);
}

.student-inner-right .fee-box p {
  font-size: 12px;
  font-family: 'ns-regular';
  margin: 0 !important;
}

.student-inner-right .fee-box p:last-child {
  font-size: 18px;
  font-family: "ns-bold";
  line-height: 120%;
  margin: 0;
}

.text-dark {
  color: var(--black);
}

.student-inner-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.student-details {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.student-name {
  font-size: 24px;
  font-family: "ns-bold";
  line-height: 120%;
}

.success-tag {
  background: var(--tag-publish);
  padding: 5px 10px;
  font-size: 14px;
  font-family: "ns-semibold";
  color: var(--green);
  border-radius: 5px;
}

.primary-tag {
  background: var(--light-blue);
  padding: 5px 10px;
  font-size: 14px;
  font-family: "ns-semibold";
  color: var(--blue);
  border-radius: 5px;
}

.draft-tag {
  background: var(--tag-draft);
  padding: 5px 10px;
  font-size: 14px;
  font-family: "ns-semibold";
  color: var(--dark-grey);
  border-radius: 5px;
}

.student-mail {
  margin-top: 10px;
  font-size: 14px;
  line-height: 150%;
  color: var(--dark-grey);
  font-family: "ns-semibold";
}

.student-fee-table {
  width: 100%;
  margin-top: 20px;
  border: 1px solid var(--border);
  border-radius: 5px;
  overflow: visible;
}

.student-fee-table .student-inner-right {
  padding: 0;
}

.student-fee-table .student-info-outer {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid var(--border);
}

.fee-details-table thead th {
  box-shadow: none;
  border-bottom: 1px solid var(--border);
}

.fee-details-table .primary-link {
  text-decoration: underline;
}

.text-18 {
  font-size: 18px;
}

.dFlex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.timelineWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.timelineleft {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  flex-wrap: wrap;
  max-width: 220px;
  width: 100%;
}

.timelinebutton {
  font-family: 'ns-semibold';
  font-size: 14px;
  line-height: 20px;
  color: var(--black);
  padding: 10px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.timelinebutton.active {
  color: #004E9A;
  background: var(--white);
}

.timelinebutton.active svg path {
  fill: #004E9A;
}

.timelineRight {
  max-width: calc(100% - 230px);
  width: 100%;
  background: var(--white);
  max-height: calc(100vh - 280px);
  overflow: auto;
}

.timelineHeading {
  font-family: 'ns-bold';
  font-size: 18px;
  line-height: 120%;
  padding: 15px 20px;
  border: 1px solid #F5F5F5;
}

.timelineDetailList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
}

.timelineDate {
  max-width: 72px;
  width: 100%;
  font-family: 'ns-semibold';
  color: #737373;
  font-size: 12px;
  line-height: 120%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.timelineDetailListWrapper.active {
  display: flex;
}

.timelineDetailListWrapper {
  padding: 20px;
  display: none;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  flex-wrap: wrap;
}

.timelineText {
  font-family: 'ns-regular';
  font-size: 14px;
  line-height: 120%;
  padding: 10px 12px;
  width: 100%;

  background: #FFFFFF;
  border: 1px solid #EDEDED;
  border-radius: 5px;
}

.timelineText small {
  display: block;
  width: 100%;
  position: relative;
  margin-top: 5px;
}

.timelineIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.timelineIcon::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -100%;
  height: 100%;
  width: 1px;
  background: #3aa87d;
}

.timelineDetailList:last-child .timelineIcon:after {
  display: none;
}

.timelineHeadingBtns {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.followUpButtons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.followUpButtons button {
  padding: 8px 10px;
  font-size: 13px;
  line-height: 120%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  font-family: 'ns-regular' !important;
}

.timelineFollowUp {
  gap: 10px;
}

.timelineBlank {
  align-items: center;
  justify-content: center;
}

.studentFeeOuterContainer {
  padding: 20px;
  width: 100%;
  height: calc(100vh - 111px);
  overflow: hidden;
}

.studentFeeOuterContainer .student-info-outer {}

.studentFeeOuterContainer #container-1,
.studentFeeOuterContainer #container-2,
.studentFeeOuterContainer #container-3 {
  height: 500px;
  margin-top: 20px;
}

.studentFeeOuterContainer .student-container {
  margin: 0;
  width: 100%;
}

.studentFeeOuterContainer .student-fee-table {
  margin: 0;
}

.studentFeeOuterContainer .fee-details-table {
  width: max-content;
}

.w-100 {
  width: 100% !important;
}

.absoluteBtn {
  position: absolute;
  right: 20px;
  top: 10px;
}




.studentProfilerTabContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.studentProfileBoxOuter {
  display: flex;
  width: 100%;
  gap: var(--spacing-16px);
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: var(--spacing-20px) var(--spacing-16px);
}

.studentProfileBoxContainer {
  display: flex;
  flex-direction: column;
  width: 32%;
  gap: var(--spacing-8px);
}

.studentProfileBoxContainer .studentProfileBox {
  display: flex;
  flex-direction: column;
  width: max-content;
  padding: var(--spacing-8px) var(--spacing-12px);
  background: var(--white);
  border: 1px solid var(--border);
  border-radius: 10px;
  width: 100%;

}

.studentProfileBoxContainer:first-child {
  width: 35%;
}

.studentProfileDetails {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
}

.studentProfileDetails .studentProfileDetail {
  width: 48%;
  font-size: 13px;
  font-family: 'ns-bold';
  line-height: 120%;
}

.studentProfileDetails .studentProfileDetail span {
  font-family: 'ns-regular';
  color: var(--black-3);
}

.studentProfileBoxInner {
  display: flex;
  flex-direction: column;
  width: auto;
  gap: 5px;
}

.studentProfileBoxInner .heading {
  font-size: 18px;
  color: var(--primary-4);
  font-family: 'ns-semibold';
}


.studentProfileBoxInner .text {
  font-size: 13px;
  color: var(--black-3);
}

.studentProfileBoxInnerContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: var(--spacing-16px);
}

.studentProfileBoxInnerContainer .studentProfileBoxInner {
  width: 48%;
}

.studentProfileBox.orange{
  background: #FEF9F5;
  border-color: #FCE3CE;
}

.studentProfileBox.orange .studentProfileBoxInner .heading{
  color: #F28D30;
}

.studentNameBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-8px);
}

.studentNameBox .studnetName {
  font-size: 20px;
  font-family: 'ns-semibold';
  line-height: 120%;
  color: var(--primary-4);
}

.studentNameBox .letter {
  padding: 0px 10px;
  background: var(--primary-4);
  font-size: 20px;
  color: var(--white);
  border-radius: 5px;
  font-family: 'ns-regular';
}


.feeDetailsBoxContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-8px);

}

.feeDetailsBox {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 33%;
  border-radius: 5px;
  padding: var(--spacing-8px) var(--spacing-12px);
}

.feeDetailsBox.danger {
  border: 1px solid var(--danger-1);
  background: #fff8f8;
}

.feeDetailsBox.danger .heading {
  color: #FF5F57;

}

.feeDetailsBox.success {
  border: 1px solid var(--success-1);
  background: #f7fcf9;
}

.feeDetailsBox.success .heading {
  color: var(--success-5);
}

.feeDetailsBox.primary {
  border: 1px solid var(--primary-1);
  background: #f3f8fc;
}

.feeDetailsBox.primary .heading {
  color: var(--primary-5);
}

.feeDetailsBox .heading {
  font-size: 18px;
  font-family: 'ns-bold';
}

.feeDetailsBox .text {
  font-size: 13px;
}

.studentProfileDataTabsContainer {
  display: flex;
  border-top: 1px solid var(--border);
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: sticky;
  top: 100px;
  overflow: hidden;
  background: var(--white);
}

.studentProfileDataTabsOuter {
  width: 30%;
  position: sticky;
  top: 0px;
  max-width: 230px;
 
}

.studentProfileDataTabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-4px);
  padding: var(--spacing-12px) var(--spacing-16px);
}

.studentProfileDataTab{
  padding: var(--spacing-8px);
  background: var(--white);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  transition: 0.4s;
}

.studentProfileDataTab:hover{
  background: var(--grey-1);
}

.studentProfileDataTab div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-12px);
}

.studentProfileDataTab p{
  font-size: 14px;
  font-family: 'ns-regular';
  color: var(--black-5);
}

.studentProfileDataTab.active{
  background: var(--black-5);
}

.studentProfileDataTab.active svg path{
  fill: var(--white);
}

.studentProfileDataTab.active p{
  color: var(--white);
}

.studentProfileDataContainer {
  width: 100%;
  height: 650px;
  overflow: hidden;
  background: #EDEDED;
  padding: var(--spacing-16px);
}

.profileTab {
  display: none;
  height: 610px;
  width: 100%;
  border-radius: 10px;
  overflow: auto;
  position: relative;
}

.profileTab.active{
  display: block;
}

.profileTabHeader {
  position: sticky;
  top: 0;
  background: var(--black-5);
  padding: var(--spacing-16px) var(--spacing-20px);
  z-index: 1;
}

.profileTabHeader .heading {
  font-size: 14px;
  color: var(--white);
}

.profileTabBody {
  border-radius: 0 0 10px 10px;
  background: var(--bg-color);
  border: 1px solid #d2d2d2;
  border-top: 0;
  padding: var(--spacing-20px);
}

.academicDetailsContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--spacing-20px);
  flex-wrap: wrap;
  border-bottom: 1px solid var(--border);
  padding-bottom: var(--spacing-16px);
}

.academicDetailsBox {
  width: 49%;
  height: 191px;
  background: var(--white);
  border: 1px solid var(--border);
  border-radius: 10px;
  overflow: hidden;
}

.academicDetailsBoxHeader {
  border-bottom: 1px solid var(--border);
  padding: var(--spacing-8px) var(--spacing-16px);
}

.academicDetailsBoxHeader .heading {
  font-size: 16px;
  font-family: 'ns-semibold';
}

.academicDetailsBoxBody {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacing-16px);
  justify-content: space-between;
  padding: var(--spacing-16px);
}

.academicDetailsBoxBody .academicDetailText {
  font-size: 13px;
  width: 48%;
  font-family: 'ns-bold';
}

.academicDetailsBoxBody .academicDetailText span {
  font-family: 'ns-regular';
  color: var(--dark-grey);
}

.semesterWiseData {
  padding: var(--spacing-16px) 0;
  border-bottom: 1px solid var(--border);
}

.semesterWiseData .heading {
  font-size: 14px;
  font-family: 'ns-semibold';
}

.semesterWiseTable {
  margin-top: 10px;
  background: var(--white);
  border-radius: 10px;
  width: 100%;
  text-align: left;
  border: 1px solid var(--border);
  border-collapse: collapse;
}

.semesterWiseTable th {
  font-size: 12px;
  border: 1px solid var(--border);
  padding: var(--spacing-12px) var(--spacing-8px);
  font-family: 'ns-regular';
  color: var(--dark-grey);
}

.semesterWiseTable td {
  font-size: 12px;
  border: 1px solid var(--border);
  padding: var(--spacing-12px) var(--spacing-8px);
  font-family: 'ns-bold';
  color: var(--black-5);
}

.semesterWiseTable tr td:first-child {
  font-family: 'ns-regular';
  color: var(--dark-grey);
}

.workDetailsContainer {
  width: 100%;
  background: var(--white);
  border: 1px solid var(--border);
  margin-top: var(--spacing-16px);
  border-radius: 10px;
}

.workDetailHeader {
  padding: var(--spacing-8px) var(--spacing-16px);
  width: 100%;
  border-bottom: 1px solid var(--border);
}

.workDetailHeader .heading {
  font-size: 16px;
  font-family: 'ns-bold';
}

.workDetailBody {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: var(--spacing-16px);
  padding: var(--spacing-16px);
}

.workDetail {
  width: 23%;
  font-size: 13px;
  font-family: 'ns-bold';
}

.workDetail span {
  font-family: 'ns-regular';
  color: var(--dark-grey);
}

.statementBox {
  margin-top: var(--spacing-20px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.statementBox .heading {
  font-size: 16px;
  font-family: 'ns-bold';
}

.statementBox .statementQuestion {
  font-size: 13px;
  font-family: 'ns-bold';
  margin-top: var(--spacing-16px) !important;
}

.statementBox .answer {
  margin-top: var(--spacing-12px) !important;
  font-size: 13px;
  line-height: 150%;
  font-family: 'ns-regular';
  color: var(--dark-grey);
}

.filesContainer {
  background: var(--white);
  border-radius: 10px;
  border: 1px solid var(--border);
  margin-top: var(--spacing-24px);
}

.filesContainer .filesContainerHeader {
  padding: var(--spacing-8px) var(--spacing-16px);
  border-bottom: 1px solid var(--border);
}

.filesContainerBody {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--spacing-20px);
  flex-wrap: wrap;
  padding: var(--spacing-16px);
}

.filesContainer .filesContainerHeader .heading {
  font-size: 16px;
  font-family: 'ns-semibold';
}

.fileContainer {
  width: 32%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8px);
}

.fileContainer .heading {
  font-size: 13px;
  font-family: 'ns-semibold';
}

.downloadSuccessBtn {
  background: #EBF7F1;
  border-radius: 4px;
  width: max-content;
  padding: var(--spacing-8px);
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-8px);
  cursor: pointer;
}

.downloadSuccessBtn p {
  font-size: 13px;
  color: #32B275;
  font-family: 'ns-semibold';
}

.profileTab .fee-details-table th{
  background: var(--black-5);
  color: var(--white);
}

.groupBtn{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 10px;
}

.withButton{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.withButton{
  padding: 9px 15px;
}

.profileTab .fee-details-table.singleStudentTableHead thead th{
  background: var(--grey-2);
  color: var(--black-5);
  min-width: 200px;
}

.profileTab .fee-details-table.singleStudentTableHead thead{
  top: 0px;
}

.fee-details-table.singleStudentTableHead{
  width: max-content !important;
}

.singleStudentTableOuter{
  width: 100%;
  overflow: auto;
  height: 550px;
}


/* new design starts */

.particularStudent .academicDetailsBoxHeader .heading{
  font-size: 12px;
}

.particularStudent .academicDetailsBoxBody .academicDetailText{
  font-size: 12px;
}

.particularStudent .academicDetailsBoxHeader{
  color: var(--white);
  padding: 12px;
  background: var(--black-5);
}

.particularStudent .semesterWiseData:not(:first-child){
  margin-top: 15px;
}

.particularStudent .semesterWiseData{
  border: 1px solid var(--border);
  border-radius: 10px;
  background: var(--white);
  padding: 0;
  overflow: hidden;
}

.semesterWiseData .heading{
  border-bottom: 1px solid var(--border);
  padding: 15px;
  font-family: 'ns-semibold' !important;
  color: var(--white);
  background: #171717;
  font-size: 13px;
  line-height: 140%;
}

.particularStudent .semesterWiseData table{
  border-radius: 0;
  border: none;
  margin-top: 0;
}

.particularStudent .semesterWiseData table thead{
  background: var(--black-5);
}

.particularStudent .academicDetailsBox{
  height: max-content;
}

.particularStudent .semesterWiseTable td:first-child{
  border-left: 0;
}

.particularStudent .semesterWiseTable td:last-child{
  border-right: 0;
}

.particularStudent .semesterWiseTable th{
  border: 0;
  color: var(--black-4);
  font-family: 'ns-semibold';
  background: var(--grey-3);
  padding: 12px;
}

.particularStudent .semesterWiseTable td{
  border-bottom: 0;
  padding: 12px;
  font-family: 'ns-bold' !important;
}

.newDesignClr{
  border-top: 0;
  background: #fafafa;
}

.newDesignClr .studentProfileDataContainer{
  background: #fafafa;
}

.newDesignClrBox{
  padding-bottom: 0;
}

.particularStudent .darkHeads th{
  color: #fff;
  background: #171717;
  font-size: 13px;
}