.muloan-body {
  max-height: calc(100vh - 50vh);
}
.muloan-body .loan-step-content {
  height: 100%;
  max-height: 100%;
}
.border-box {
  position: relative;
}
.loan-radio-grp {
  margin-top: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.border-box .create-label {
  position: relative;
  padding-left: 30px;
  font-size: 18px;
  line-height: 120%;

  margin-top: 15px;
  padding-bottom: 15px;
}
.border-box .create-label:not(:last-child) {
  border-bottom: 1px solid var(--border);
}
.border-box .create-label:last-child {
  border-bottom: 2px dashed var(--border);
}
.border-box .radio-btn:checked ~ .radiomark {
  background: var(--white);
  border-color: var(--green);
}
.border-box .radiomark::after {
  background: var(--green);
}
.border-box .radio-btn:checked + .create-label {
  color: var(--green);
}
.muloan-body .amount {
  font-size: 20px;
  line-height: 120%;
  font-family: "ns-semibold";
}
.muloan-body .popup-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
}
.muloan-body .popup-footer .green-btn {
  padding: 10px 40px;
  border-radius: 5px;
  font-size: 18px;
  font-family: "ns-regular";
}
.muloan-body .text-success {
  font-size: 18px;
  line-height: 120%;
  font-family: "ns-semibold";
}
.light-text {
  color: var(--grey);
  font-size: 16px;
  font-style: italic;
  font-family: "ns-light";
}
.green-btn-a {
  font-size: 18px;
  font-family: "ns-semibold";
  background: var(--white);
  border: 1px solid var(--green);
  color: var(--green);
  padding: 10px 40px;
  border-radius: 300px;
  cursor: pointer;
}
.light-tag {
  font-size: 13px;
  background: var(--bg-color);
  color: var(--black);
  border-radius: 5px;
  padding: 8px 12px;
  border: 1px solid var(--border);
  font-family: "ns-semibold";
}
.box-left .heading .text-success {
  display: inline;
  margin-left: 5px;
  font-size: 16px;
}
