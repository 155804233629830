.updateBoxAutomation .popup-container{
    width: 760px;
    padding: 0;
}
.updateBoxAutomation .popup-body{
    height: 55vh;
}
.updateBoxTableOuter{
    width: 100%;
    height: 420px;
    overflow: auto;
    border: 1px solid var(--border);
    border-radius: 10px;
}

.updateBoxTable{
    text-align: left;
    width: 100%;
}

.updateBoxTable tr th,
.updateBoxTable td {
    padding: 15px 10px;
    font-family: "ns-semibold";
    text-align: left;
}

.updateBoxTable tr th {
    color: var(--white);
    background: var(--black-5);
    text-transform: capitalize;
}

.updateBoxTable tr td {
    color: var(--black);
    font-size: 14px;
    border-bottom: 2px solid var(--bg-color);
    position: relative;
}

.updateBoxTable thead {
    box-shadow: 0px 4px 2px rgb(0 0 0 / 2%);
    position: sticky;
    top: 0px;
    z-index: 1000;
}

.updateBoxTable tbody {
    overflow: auto;
}

.updateBoxTable tr {
    border-bottom: 1px solid var(--grey);
    position: relative;
}

.form2Col{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
}

.form-group{
    padding: 0;
}

.customSelect{
    border-radius: 5px;
}