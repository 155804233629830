.interview-popup {
  width: 600px;
  height: calc(100% - 200px);
  padding: 0;
}
.show-popup .interview-popup .popup-form-outer {
  display: block;
}
.interview-popup .popup-form-outer {
  position: absolute;
  width: 100%;
  overflow: auto;
  top: auto;
  height: 100%;
  max-height: calc(100% - 75px);
  left: 0;
  transition: 0.3s;
  left: -100%;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  display: none;
}
.show-popup .popup-form-outer.active {
  left: 0%;
  display: block;
  visibility: visible;
  opacity: 1;
  overflow: auto;
}

.form-grp {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.form-grp:last-child {
  margin-bottom: 10px;
}
.form-grp label {
  font-size: 13px;
  line-height: 120%;
  font-family: "ns-semibold";
  color: var(--dark-grey);
}

.form-grp .form-input {
  border: 1px solid var(--border);
  border-radius: 5px;
  font-size: 13px;
  font-family: "ns-semibold";
  line-height: 120%;
  padding: 12px;
}
.popup-form {
  padding: 0 20px;
}
.interview-popup .form-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.interview-popup .popup-body {
  height: 100%;
  max-height: calc(100% - 63px);
  overflow: hidden;
  padding: 0;
  position: relative;
}
.interview-popup .radio-group {
  justify-content: flex-start;
  gap: 20px;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.interview-popup .popup-header {
  padding-bottom: 0;
}
.interview-popup .form-footer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.page-dot {
  cursor: pointer;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--dark-grey);
  transition: 0.2s;
}
.page-dot.active {
  width: 20px;
  border-radius: 10px;
}
.ns-Font{ font-family: "ns-semibold";}
