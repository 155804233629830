.financeInvoice{
    padding: 0;
    width: 100%;
    max-width: 1000px;
}
.popupLogoHeader{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--border);
}

.popupLogoHeader .btnGrp{
    width: max-content;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.popupLogoHeader .success-btn{
    border-radius: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.ticktInfoOuter{
    gap: 20px;
    padding: 30px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid var(--border);
}
.ticktInfoBox{
    width: 30%;
    display: flex;
    flex-direction: column;
}

.ticktInfoBox .title{
    font-size: 14px;
    line-height:120%;
    color: var(--dark-grey);
    font-family: 'ns-semibold';
}

.ticktInfoBox .value{
    font-size: 18px;
    font-family: 'ns-bold';
    color: var(--black);
}
.ticktInfoBox .value-2{
    font-size: 16px;
    line-height: 120%;
    font-family: 'ns-regular';
    color: var(--dark-grey);
}

.financeInvoice .form-2-col{
    padding: 30px 0;
    border-bottom: 1px solid var(--border);
}

.financeInvoice .form-group{
    padding: 0;
}

.approvingContainer{
    padding: 30px 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid var(--border);
}

.approvingBox{
    width: 49%;
}
.approvingBox .label{
    font-size: 14px;
    font-family: 'ns-regular';
}
.approvingBox .name{
    font-size: 18px;
    font-family: 'ns-bold';
    color: var(--dark-grey);
}

.keys{
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
    flex-wrap: wrap;
}

.label.mt-20{
    margin-top: 20px !important;
}

.approvingBox .desc{
    margin-top: 10px !important;
    font-size: 14px;
    line-height: 150%;
    font-family: 'ns-semibold';
}

.uploadedDocContainer{
    gap: 10px;
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
}

.uploadedFileDetailBox{
    width: 100%;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.uploadedFileDetailBox .light-btn-a{
    padding: 5px;
    font-size: 13px;
    font-family: 'ns-regular';
    line-height: 120%;
    color: var(--light-black);
}
.fileName{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    width: 39%;
}
.fileName p{
    font-size: 13px;
    color: var(--green);
    font-family: 'ns-regular';
    overflow:hidden;
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap; 
    width:80%;
}

.financePopupBody{
    height: 100%;
    max-height:600px
}

.attachedFileContainer .title{
    font-size: 14px;
    font-family: 'ns-regular';
}

.amountOuter{
    font-size: 16px;
    font-family: 'ns-semibold';
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 30px;
    color: var(--green);
    border-top: 2px solid var(--green);
    border-bottom: 2px solid var(--green);
}

.flex-start{
    align-items: flex-start;
}