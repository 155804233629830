.vendorPopup{
    max-width: 600px;
    width: 100%;
    padding: 0;
}

.vendorPopup .form-2-col{
    padding: 0;
}

.vendorPopup .form-grp{
    width: 100%;
}

.flex-end{
    justify-content: flex-end;
}