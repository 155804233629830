.component-outer {
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  right: 300px;
  top: 0;
  background-color: var(--white);
  transition: right 0.5s;
}
.component-outer.slide-in {
  right: 0;
}
.sec-1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 50px;
  padding: 15px;
  width: 100%;
}
.sec-2 {
  border-top: 1px solid var(--bg-color);
  display: flex;
  padding: 15px;
  width: 100%;
  align-items: center;
}
.default-value {
  visibility: hidden;
}
.sec-2-inner {
  width: 100%;
}

.layout-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-size: auto;
  background-color: var(--bg-color);
  border-radius: 5px;
  color: var(--light-black);
  margin-top: 10px;
}
.question-c-text-area {
  width: 100%;
  /* max-width: 250px; */
  min-height: 135px;
  max-height: 135px;
  resize: none;
  margin-top: 10px;
  padding: 10px;
  overflow: auto;
  border: none;
  background-color: var(--bg-color);
  border-radius: 5px;
}

.rating-component-outer {
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--white);
  transition: 0.5s;
  z-index: 1;
}
.options-comp {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
}
.sub-option {
  position: relative;
  width: 100%;
  display: flex;
  border: 1px solid var(--bg-color);
  border-radius: 5px;
}
.sub-option-left {
  min-width: 27%;
  padding: 10px;
  color: var(--red);
  background: var(--bg-color);
}
/* .sub-option-right{
 
  padding:10px;
} */
.add-option {
  gap: 10px;
  width: 100%;
  display: flex;
  color: var(--red);
  align-items: center;
  cursor: pointer;
  border: 0;
  background: none;
}
.add-option img {
  min-width: 17px;
}
.sub-option > input {
  border: none;
  width: 100%;
  padding: 10px;
  /* border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; */
}

/* .dynamic-input .sec-2:first-child {
  max-height: 210px;
  overflow: auto;
}
.sec-2.scrollbar .sec-2-inner {
  max-height: 200px;
} */
.option-remove {
  visibility: hidden;
  opacity: 0;
  background: transparent;
  cursor: pointer;
  position: absolute;
  right: 1%;
  top: 26%;
}
.sub-option:hover .option-remove {
  opacity: 1;
  visibility: visible;
}
