.mainSection.flexWrap{
    flex-wrap: wrap;
}

.oneWrap{
    padding: 15px;
    padding-left: 75px;
    display: block;
    position: relative;
    max-width: 100%;
    overflow: auto;
}

.flexbox {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: inherit;
}

.threexBoxWrap {
    flex-wrap: wrap;
    gap: 20px;
}

.programBoxWrap {
    max-width: 360px;
    width: 100%;
    padding: 20px;
    background: var(--white);
    position: relative;
    z-index: 1;
    border-radius: 6px;
    overflow: hidden;
}

.threexBoxWrap .programBoxWrap, 
.threexBoxWrap .graphWrapper {
    max-width: calc(33% - 10px);
}

.programBoxWrap::before {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 65%;
    z-index: -1;
    border-radius: 6px;
}

.LightblueFeeBox::before {
    background: url('https://cdn.mastersunion.org/assets/imgV2/linesGroupDinero.png') var(--blue);
}

.programDetails {
    text-align: center;
    color: var(--white);
}

.programCount {
    font-weight: 700;
    font-size: 36px;
    line-height: 100%;
    display: block;
    position: relative;
}

.programText {
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    margin-top: 5px;
    display: block;
    position: relative;
}

.boxDetailed {
    padding: 15px;
    background: var(--white);
    border: 1px solid #DDDDDD;
    border-radius: 6px;
}

.chartHeading {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: var(--black4);
    display: block;
    width: 100%;
    text-align: center;
}

.graphWrapper {
    background: var(--white);
    max-width: 100%;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.graphHeading {
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 1px solid var(--grey-3);
}

.headingGraph {
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
}

.graphBody {
    padding: 20px;
    position: relative;
    background: var(--white);
    display: block;
}

.listCountBox {
    justify-content: space-between !important;
    padding: 2px;
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
}

.divider {
    display: block;
    height: 1px;
    border-top: 1px solid var(--grey-3);
    width: 100%;
    max-width: 240px;
}

.stateCount {
    font-weight: 700;
    color: var(--blue);
}

.listCountBox:not(:first-child) {
    margin-top: 15px;
}

.legendsGraph {
    justify-content: center;
    column-gap: 40px;
    row-gap: 10px;
    flex-wrap: wrap;
}

.chartDetail {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    position: relative;
    max-width: 50%;
}

.blueDot::before {
    background: var(--blue) !important;
}

.greenDot::before {
    background: var(--green) !important;
}

.femaleCat::before {
    background: rgb(255, 84, 13) !important;
}

.chartDetail::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgb(255, 184, 67);
}

.min284{
    max-height: 284px;
    overflow: auto;
}

.min450{
    min-height: 450px;
}

.oneXWrapper {
    display: block;
    position: relative;
    width: 100%;
}

.multi4Box {
    background: var(--white);
    gap: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 24px 20px;
}

.listBoxWrapper .graphBody {
    flex-wrap: wrap;
    gap: 20px;
    display: flex;
    max-height: 284px;
    overflow: auto;
}

.listBoxIcon {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: var(--black3);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    width: calc(50% - 10px);
}

.chartHeadingSmall{
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: var(--black4);
    display: block;
    width: 100%;
    text-align: center;
}

.threexBoxWrap .graphWrapper .legendsGraph{
    justify-content: center;
    column-gap: 40px;
    row-gap: 10px;
}

@media (max-width: 1440px){
    .multi4Box .coverGraph{
        max-width: calc(25% - 15px);
    }
}

.wrapGraphBody{
    max-height: 280px;
    overflow: auto;
    align-items: flex-start !important;
}

.wrapGraphBody .graphBody{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 10px;
    min-height: 36px;
}

.wrapGraphBody .listCountBox{
    max-width: calc(33.33% - 21px);
    width: 100%;
    min-height: 35px;
}

.wrapGraphBody .listCountBox:not(:first-child){
    margin-top: 0;
}