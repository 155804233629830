.interviewListContainer{
    width: calc(100% - 40px);
    margin: 0 auto;
    display: block;
    height: calc(100% - 180px);
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    overflow: auto;
    background: var(--white);
}
.interviewListContainer.containerNew{
    width: auto;
}
.interviewListTable{
    width: 100%;
    text-align: left;

}

.interviewListTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.interviewListTable thead th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}



.interviewListTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.interviewListTable tbody tr:hover td{
    background: var(--grey-1);
}

.createInterviewSlotContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-20px);
    width: 700px;
    margin: 20px auto;
    padding: var(--spacing-20px);
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    background: var(--white);
}
.createInterviewSlotContainer .react-date-picker__wrapper{
    max-width: 250px;
}
.createInterviewSlotContainer .formGroup{
    gap: 5px;
    width: 100%;
}

.createInterviewSlotContainer .feedbackRadioGroup{
    justify-content: flex-start;
    gap: 10px;
}

.slotTableOuter{
    height: 300px;
    border-radius: 6px;
    overflow: auto;
    border: 1px solid var(--grey-3);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding:0 var(--spacing-8px);

}
.slotTable{
    width: 100%;
    text-align: left;
}

.slotTable td{
    font-size: 14px;
    padding: 10px;
    border-bottom: 1px solid var(--grey-3);
}

.timeSlot{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
}

.timeSlot .rc-time-picker-input{
    width: 80px;
}

.slotTable td:first-child{
    width: 10px;
    padding: 0;
}

.slotTable td:nth-child(2){
    font-family: 'ns-semibold';
}

.slotTable .checkboxContainer{
    top: -4px;
}

.timeSlots{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}

.slotTable tr:last-child td{
    border-bottom: none;
}

.selectInterviewSlotContainer{
    width: 650px;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    border-radius: 6px;
    overflow: auto;
    background: var(--white);
    border: 1px solid var(--grey-3);
}
.selectInterviewSlotContainerHeader{
    background: var(--black-5);
    color: var(--white);
    font-size: 16px;
    font-family: 'ns-semibold';
    padding: var(--spacing-20px) var(--spacing-16px);
}

.react-calendar__month-view__days__day--weekend{
    color: var(--black-5) !important;
}

.selectInterviewContainer{
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 10px 15px;
    border-right: 1px solid var(--grey-3);
}
.selectInterviewContainer .react-calendar{
    width: 100%;
    max-width: 100%;
    border: none;
}
.selectInterviewContainer .react-calendar__tile{
    padding: 16px 12px;
    max-height: 50px;
}
.selectInterviewContainer .react-calendar .react-calendar__navigation__arrow{
    border-radius: 50%;
    min-height: 30px;
}

.selectInterviewContainer .react-calendar__tile:disabled{
    background-color: #f7f7f7;
}
.selectInterviewContainer .react-calendar__month-view__days__day--weekend:disabled {
    color: rgba(16,16,16,0.3) !important;
}
.selectInterviewContainerInner{
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.selectInterviewContainer:last-child{
    width: 250px;
    border: none;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}
.slot{
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
    padding: 6px;
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--primary-4);
    width: 100%;
    border-radius: 6px;
    border: 2px solid var(--primary-4);
}

.slots.active .slot{
    width: 50%;
    background: var(--black-5);
    border-color: var(--black-5);
    color: var(--white);
}
.slots{
    width: 100%;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
}
.slots.active .primary-btn-a{
    width: 50%;
    display: block;
}

.slots .primary-btn-a{
    display: none;
}

.slotsOuter{
    margin-top: var(--spacing-16px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}
.flex-start{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.gap0{
    gap: 0;
}

.react-date-picker{
    width: 100%;
}

.calender-label{
    width: 100%;
    font-family: "ns-semibold";
    font-size: 14px;
    line-height: 20px;
}


.react-date-picker__wrapper{
    min-width: 260px;
    min-height: 32px;
    padding: 6px 8px;
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    color: var(--black-4);
    cursor: pointer;
    /* background: var(--grey-1); */

    /* background: var(--white) url(https://mastersunion.s3.ap-south-1.amazonaws.com/assets/imgV2/datepickerpmcalender.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 97% 50%; */
}

.react-date-picker__inputGroup__input{
    color: var(--grey-4);
    cursor: pointer;
}

.react-date-picker__inputGroup__input:focus-visible{
    box-shadow: none;
    border: none;
    outline: none;
    background: transparent;
}

.react-date-picker__inputGroup__input:focus{
    background: transparent;
}
.react-date-picker__calendar{
    z-index: 2;
}

/* .react-date-picker__button:enabled{
    display: none;
} */


/* .react-date-picker__button{
    display: none;
} */

.react-date-picker__clear-button{
    display: none;
}

.react-date-picker__calendar-button{
    position: relative;
    padding: 0;
    min-width: 16px;
}

.react-date-picker__calendar-button::after{
    content: "";
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    background: url(https://mastersunion.s3.ap-south-1.amazonaws.com/assets/imgV2/datepickerpmcalender.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.react-date-picker__calendar{
    width: auto;
}

.react-calendar{
    padding: 16px 10px;
    border-radius: 6px;
    margin-top: 2px;
    max-width: 260px;
    border: 1px solid var(--grey-4);
}

.react-calendar__navigation{
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--grey-2);
    height: auto;
}

.react-calendar__navigation button{
    position: relative;
}

.react-calendar__navigation__label{
    order: 1;
    text-align: left;
}

.react-calendar__navigation__prev2-button{
    display: none;
}

.react-calendar__navigation__next2-button{
    display: none;
}

.react-calendar__navigation__prev-button{
    order: 2;
    margin-right: 5px !important;
    color: transparent;
    min-width: 30px !important;
}

.react-calendar__navigation__prev-button::after{
    content: "";
    background: url(https://mastersunion.s3.ap-south-1.amazonaws.com/assets/imgV2/datepickerpmleft.svg);
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;    
}

.react-calendar__navigation__next-button{
    order: 3;
    color: transparent;
    min-width: 30px !important;
}

.react-calendar__navigation__next-button::after{
    content: "";
    background: url(https://mastersunion.s3.ap-south-1.amazonaws.com/assets/imgV2/datepickerpmright.svg);
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;    
}

.react-calendar__navigation__label__labelText--from{
    font-size: 12px;
    font-family: 'ns-semibold';
    line-height: 20px;
    color: var(--black-5);
}

.react-calendar__month-view__weekdays__weekday abbr{
    text-decoration: none;
}

.react-calendar__tile{
    max-height: 34px;
    border-radius: 50%;
    font-size: 12px;
}

.react-calendar button{
    transition: 0.1s ease-in;
}

.react-calendar__tile--active{
    background-color: var(--primary-4) !important;
    color: var(--white) !important;
}
/*temp */
.flexbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.flexbox .create-input{
    width: 92%;
}
/*temp */

.react-calendar__tile--active:enabled:hover, 
.react-calendar__tile--active:enabled:focus{
    background-color: var(--primary-4) !important;
    color: #f7f7f7 !important;
    transition: 0.1s ease-in !important;
}

.react-calendar button:enabled:hover{
    background-color: var(--primary-1);
    transition: 0.1s ease-in;
}

.isFilled .react-date-picker__wrapper{
    color: var(--black-4)
}

.isFilled .react-date-picker__inputGroup__input{
    color: var(--black-4)
}

.react-calendar__tile--now{
    background: var(--white);
    color: var(--primary-6) !important;
    border: 1px solid var(--primary-6) !important;
}
.w100{
    width: 100%;
}
.react-calendar__tile--now{
    background: transparent !important;
}
.pageHeader{
    border-bottom: 1px solid var(--grey-3);
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: var(--spacing-8px) var(--spacing-20px);
    background: var(--white);
}
.createInterviewSlotContainerOuter{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 54px);
}
.pageToggler {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
    font-size: 14px;
    font-family: 'ns-semibold';
    cursor: pointer;
}
.backBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--black-1);
}

.pageToggler.active {
    color: var(--primary-4);
}
.filterBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-8px) var(--spacing-20px);
}
.emptyTableBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: var(--spacing-8px);
}
.emptyTableBox .heading {
    font-size: 28px;
    line-height: 36px;
    color: var(--black-4);
    font-family: 'ns-bold';
}
.emptyTableBox .text {
    width: 410px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: var(--black-3);
    font-family: 'ns-regular';
}
.filterBarRight {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}
.fiterBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-4px);
    border-radius: 6px;
    outline: none;
    padding: 10px;
    background: var(--white);
    border: 1px solid  var(--grey-5);
    font-size: 14px;
    color: var(--black-4);
    cursor: pointer;
}
.searchBox {
    border-radius: 5px;
    border: 1px solid var(--grey-3);
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: var(--spacing-8px);
    background: var(--white);
    gap: var(--spacing-8px);
}
.searchInput {
    width: 100%;
    border: none;
    outline: none;
    color: var(--grey-5);
    background: var(--white);
}
.actionBtn {
    border: none;
    background: transparent;
    outline: none;
    position: relative;
}
.actionDropdownList {
    min-width: 160px;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    border: 1px solid var(--grey-3);
    position: absolute;
    top: 80%;
    right: 50%;
    width: max-content;
    list-style: none;
    background: var(--white);
    z-index: 1;
    border-radius: 6px;
    box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
}
.actionDropdownListItem {
    border-bottom: 1px solid var(--grey-3);
    font-family: 'ns-regular';
    font-size: 14px;
    color: var(--black-4);
    cursor: pointer;
    transition: 0.3s;
    gap: var(--spacing-8px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: var(--spacing-8px);
}
.actionDropdownListItem:hover {
    background: var(--black-4);
    color: var(--white);
}
.actionBtn.active .actionDropdownList {
    visibility: visible;
    opacity: 1;
}
.primaryText {
    color: var(--primary-4) !important;
}
.actionBtn.active img {
    background: var(--grey-2);
}
.actionBtn img {
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
}
.actionDropdownListItem:last-child {
    border: none;
    color: var(--danger-4);
}
.actionDropdownListItem:last-child:hover {
    background: var(--danger-4);
    color: var(--white);
}


.actionDropdownListItem:hover svg path {
    fill: white;
}
.pageTogglers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-20px);
}

.createInterviewSlotContainer .form-grp{
    width: 100%;
}

.createInterviewSlotContainer select{
    border-radius: 5px;
}

.createInterviewSlotContainer label{
    font-size: 16px;
}

.createInterviewSlotContainer .radio-group .create-label{
    padding-top: 0;
}

.createInterviewSlotContainer .radio-group{
    flex-direction: column;
    gap: 10px;
}

.radioContent{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.radioContent p{
    width: max-content;
}

.radioContent .create-input{
    width: 30%;
    padding: 8px;

}
.fix-radio .radio-btn{
    top: 10px;
}

.fix-radio .radiomark{
    top: 10px;
}

.createInterviewOuter{
    width: 100%;
    height: calc(100vh - 110px);
    overflow: auto;
}

.slot.booked {
    color: var(--white) !important;
    background: var(--primary-4);
  }