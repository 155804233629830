.userlist-popup {
  height: 100%;
  width: 100%;
  max-width: 400px;
  max-height: 500px;
  margin: auto;
}
ul.user-list {
  list-style: none;

  display: block;
  overflow: auto;
}

li.user-list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px 0;
}

.user-list-item p.text-1 {
  color: var(--light-black);
}
.userlist-popup .popup-body {
  height: 360px;
}

.userlist-popup .popup-heading{
  padding-bottom: 10px;
}
.userlist-popup .popup-body{
  padding: 0 20px;
  padding-top: 10px;
}
.userlist-popup .popup-header{
  padding-bottom: 0;
}