.club-preview-header {
  justify-content: space-between;
}
.club-preview-header .btn-group {
  gap: 10px;
}
.club-preview-header .btn-group .dark-btn-outline {
  display: flex;
}
.preview-container {
  height: calc(100vh - 120px);
  width: 100%;
  background: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
.main-container {
  max-width: 800px;
  width: 100%;
  height: 100%;
  padding: 20px 0;
}
.club-header {
  height: 100%;
  max-height: 320px;
  width: 100%;
  border-radius: 5px;
  background: var(--white);
}
.header-top {
  position: relative;
  width: 100%;
  height: 65%;
}

.header-top img {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}
img.club-image {
  position: absolute;
  width: 80px;
  height: 80px;
  object-fit: cover;
  bottom: -10px;
  left: 15px;
  border-radius: 5px;
  /* border: 1px solid var(--border); */
}

.header-bottom {
  height: calc(100% - 65%);
  padding: 10px 15px;
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--border);
  border-top: none;
  border-radius: 0 0 5px 5px;
}

.club-detail {
  display: flex;
  width: 60%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.heading-3 {
  font-family: "ns-bold";
  font-size: 16px;
  color: var(--dark-grey);
  text-transform: capitalize;
}
.btn-group {
  display: flex;
  gap: 10px;
}
.header-bottom .btn-group {
  align-items: flex-start;
}
.club-btn {
  display: flex;
  gap: 5px;
  align-items: center;
  border: 1px solid var(--border);
  background-color: var(--bg-color);
  color: var(--dark-grey);
  padding: 5px 10px;
  border-radius: 300px;
  font-family: "ns-semibold";
  font-size: 12px;
  cursor: pointer;
}
.club-desc {
  font-family: "ns-regular";
  font-size: 12px;
  color: var(--dark-grey);
}
.club-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 10px;
}
.body-left {
  max-width: 500px;
  width: 100%;
  max-height: 394px;
  background: var(--white);
  border: 1px solid var(--border);
  border-radius: 10px;
  overflow: auto;
}
.events-block {
  width: 100%;
  height: 100%;
  position: relative;
}
.events-header {
  width: 100%;
  background: var(--white);
  position: absolute;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  box-shadow: 0px 4px 2px rgb(0 0 0 / 2%);
}
.events-body {
  height: 80%;
  overflow: hidden;
  position: relative;
  top: 50px;
}
.clear-btn {
  cursor: pointer;
  border: none;
  padding: 15px 10px;
  font-size: 12px;
  color: var(--dark-grey);
  background-color: transparent;
  font-family: "ns-semibold";
  border-bottom: 1px solid transparent;
}
.clear-btn.active {
  border-bottom: 1px solid var(--dark-grey);
}
.events-header .club-btn {
  position: absolute;
  right: 10px;
}
ul.event-list {
  list-style: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

li.event-list-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid var(--border);
  transition: 0.3s;
}

li.event-list-item:hover {
  background: var(--bg-color);
}

li.event-list-item p.text-2:last-child {
  color: var(--grey);
  transition: inherit;
}

li.event-list-item:hover p.text-2 {
  color: var(--black);
}

.events-body .clear-btn {
  color: var(--blue);
  font-size: 13px;
  padding: 0;
  text-align: center;
  width: 100%;
  font-family: "ns-bold";
}
.body-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(100% - 500px);
}

.club-holder {
  width: 100%;
  max-height: 90px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background: var(--white);
  border: 1px solid var(--border);
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
}

.club-holder img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.holder-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.holder-details .text-1 {
  font-family: "ns-bold";
  color: var(--black);
}

.holder-details .tag-active {
  font-family: "ns-semibold";
  color: var(--black);
}
.club-members {
  width: 100%;
  max-height: 295px;
  height: 100%;
  background: var(--white);
  border: 1px solid var(--border);
  border-radius: 10px;
}

.club-members li.event-list-item {
  padding: 8px 15px;
}

.club-members .event-list-item {
  justify-content: flex-start;
  align-items: center;
}

.club-members .event-list-img img {
  margin-right: 5px;
  object-fit: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

li.event-list-item.list-heading {
  padding: 12px;
  color: var(--dark-grey);
}

li.event-list-item.list-btn {
  border: none;
  padding: 0;
}

li.event-list-item.list-btn .clear-btn {
  color: var(--blue);
  font-size: 13px;
  text-align: center;
  width: 100%;
  font-family: "ns-bold";
}

.club-report {
  width: 100%;
  max-height: 297px;
  height: 100%;
  background: var(--white);
  border: 1px solid var(--border);
  border-radius: 10px;
}

.club-report li.event-list-item {
  padding: 12px;
  align-items: center;
}

.club-report li.event-list-item .heading-small {
  color: var(--light-black);
}

.club-report li.event-list-item img:nth-last-child(1) {
  display: none;
  transition: 0.4s;
}

.club-report li.event-list-item:hover img:nth-last-child(1) {
  display: block;
  transition: 0.4s;
}

.club-report li.event-list-item:hover img:nth-last-child(2) {
  display: none;
  transition: 0.4s;
}

.club-report li.event-list-item p.text-2 {
  color: var(--dark-grey);
}

.club-members li.event-list-item p.text-2 {
  color: var(--grey);
}

.list-heading:hover {
  background: var(--white) !important;
}

.club-holder .text-2 {
  line-height: 150%;
}

.club-detail .club-desc {
  line-height: 150%;
}
