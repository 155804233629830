.feedback-popup {
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 700px;
  padding: 0;
}
.feedback-popup .popup-body {
  padding: 0;
  height: calc(100% - 169px);
}
.feedback-popup .radio-group {
  justify-content: space-between;
}
.feedback-popup .popup-footer {
  padding: 20px;
  border-top: 1px solid var(--border);
}
