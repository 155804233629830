.slick-track {
  height: 100% !important;
}

.preview-screen {
  width: 100%;
  height: calc(100vh - 110px);
  background: var(--white);
  overflow: auto;
}

.preview-screen .preview-style {
  height: calc(100% - 40px);
}

.preview-screen .preview-style.multipage .inside-main {
  width: 100%;
  height: 100%;
  min-height: 700px;
  margin-top: 0;
  display: flex;
  align-items: center;
}
.preview-screen .preview-style.singlepage .inside-main {
  height: auto;
  width: 100%;
  display: block;
  margin-top: 0;
  min-height: auto;
}

.preview-screen .inside-main .inside-shell {
  padding-top: 40px;
}

.inside-shell .create-input {
  width: 80%;
}

.slick-slide {
  height: 100% !important;
}

.slick-slider {
  height: 100%;
}

.slick-list {
  height: 100% !important;
}

.slick-initialized .slick-slide > div {
  height: 100%;
}

.slick-next,
.slick-prev {
  display: none !important;
}

.preview-screen .clear-btn {
  padding: 0;
  font-size: 13px;
}
li.slick-active {
  border: 1px solid var(--black);
  border-radius: 50%;
}
/* .multipage .inside-main {
  transform: translateX(-800px);
  transition: transform ease-out 0.45s;
} */
.singlepage .inside-main.active{
  top: 0;
  animation: scrollTop 1s ease-out 1;
}
@keyframes scrollTop {
  0%{
    top: 0px;
  }
  100%{
    top: -400px;
  }
}
.feedback-footer {
  width: 100%;
  padding: 50px 20px;
  text-align: center;
  background: var(--bg-yellow);
}
.imp-question {
  position: absolute;
  color: var(--red);
  font-size: 18px;
  padding-left: 3px;
}