.mentorFeedbackPopop{
    width:500px;
    height: 500px;
}

.mentorFeedbackPopop .mentorFeedbackCardDetails{
    width:100%;
    max-width: 100%;
}

.mentorFeedbackPopop .popup-body{
    height:calc(100% - 180px);
    overflow: hidden;
}

.mentorFeedbackPopop .radio-group .create-label{
    padding-top: 0;
}

.mentorFeedbackPopop .mentorFeedbackCardDetails p{
    font-size: 16px;
}