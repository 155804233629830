.faculty-select{
    width: 250px;
}

.d-flex{
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.edit-btn img{
    
    cursor: default;
    pointer-events: none;
}
.edit-btn{
    cursor: default;
    pointer-events: none;
}

.edit-btn.active{
    cursor: pointer;
    pointer-events: all;
}
.edit-btn img{
    padding: 5px;
    border-radius:50% ;
    background: var(--bg-color);
   
}

.edit-btn.active img:nth-child(2){
    display: none;
}

.edit-btn img:nth-child(1){
    display: none;
}
.edit-btn.active img:nth-child(1){
    display: block;
}

.d-flex .action-dropdown{
    right: 25%;
}