.asked-questions ul{
  list-style: none;
  text-decoration: none;
  padding: 0;
}

.asked-questions ul li{
  cursor: pointer;
  color: var(--black);
  text-decoration: none;
  padding: 0 12px;
  display: flex;
  align-items: center;
  height: 36px;
  border-top: 1px solid var(--border);
  font-family: "ns-regular";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  position: relative;
}

.asked-questions ul li img{
  max-width: 12px;
  margin-right: 11px;
}

.asked-questions ul li:hover .remove_question{
  opacity: 1;
  visibility: visible;
  transition: 0.2s;
}

.asked-questions .remove_question{
  width: 100%;
  max-width: max-content;
  margin-right: 0;
  position: absolute;
  right: 10px;
  top: 9px;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
}
.question {
  width: 80%;
  text-overflow:ellipsis;
  overflow:hidden;
  white-space:nowrap;
}