.main-body {
  width: 100%;
  display: inline-block;
  padding-left: 60px;
  padding-right: 0px;
  height: 100%;
  min-height: calc(100vh - 50px);
}
.page-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: var(--white);
  border-bottom: 2px solid var(--border);
  position: sticky;
  top: 50px;
  z-index: 1001;
}
.filter-bar {
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.filters {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
select {
  background: var(--white);
  border: none;
  outline: none;
  padding: 10px;
  font-size: 13px;
  font-family: "ns-regular";
  color: var(--dark-grey);

  background-image: url("https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: auto 50%;

  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 30px;
  border-radius: 300px;
  border: 1px solid var(--border);
}

.feedback-container {
  width: calc(100% - 40px);
  /* padding: 20px; */
  margin-left: 20px;
  background: var(--white);
  height:100%;
  /* height: calc(100vh - 150px); */
  overflow-x: auto;
  border-radius: 5px;
}
.feedback-table {
  width: 100%;
  /* border: 1px solid #d4d4d4; */
}
.feedback-table tr th,
.feedback-table td {
  padding: 15px 10px;
  font-family: "ns-semibold";
  text-align: left;
}
.feedback-table tr th {
  color: var(--white);
  background: var(--black-5);
  text-transform: capitalize;
}
.feedback-table tr td {
  color: var(--black);
  font-size: 14px;
  border-bottom: 2px solid var(--bg-color);
  position: relative;
}

.feedback-table thead {
  box-shadow: 0px 4px 2px rgb(0 0 0 / 2%);
  position: sticky;
  top: 0px;
  z-index: 1000;
}
.feedback-table tbody {
  overflow: auto;
}
.feedback-table tr {
  border-bottom: 1px solid var(--grey);
  position: relative;
}
.table tr:nth-child(-n + 4) .action-dropdown {
  top: 0 !important;
  bottom: auto !important;
}
.table tr:nth-last-child(-n + 4) .action-dropdown {
  bottom: 0;
  top: auto;
}
.table tr:nth-last-child(-n + 3) ul.action-dropdown::after {
  top: auto;
  bottom: 20px;
}
.primary-btn.feed-btn {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: "ns-regular";
  gap: 5px;
}
.action-dropdown.active {
  visibility: visible;
  opacity: 1;
}
