.no-bg-icons{
    background: transparent !important;
}

.popupFixedContainer{
    max-height: calc(100vh - 50px);
    overflow: auto;
}
.popupFixedContainer .page-header{
    top: 0;
}
.main-body.popupFixed{
    max-height: calc(100vh - 50px);
}
