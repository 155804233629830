.tag-input{
    display: flex;
    border-radius: 5px;
    border: 1px solid #cccccc;
    width: 100%;
    padding: 6px 8px;
    overflow: hidden;
    flex-wrap: wrap;
    gap: 5px;
}
.items-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.tag-input img{
    cursor: pointer;
}
.items{
    font-family: 'ns-semibold';
    background-color: #404040;
    color: #ffffff;
    height: 27px;
    padding: 0px 12px;
    gap: 10px;
    border-radius: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    font-size: 12px;
    margin-right: 4px;

}

.tag-input input{
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 8px 10px;
    outline: none;
    width: 12vw;
}