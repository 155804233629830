.update-fees-popuop .border-box{
    padding: 0;
    margin: 0;
    height: 100%;
    gap: 0;
}

.update-fees-popuop .loan-popup-body{
    padding-top: 0 !important;
    padding: 20px;
    min-height: calc(100% - 80px);
}

.update-fees-popuop .loan-step-content{
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    padding-right: 0;
}

.update-fees-popuop .loan-options{
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
}

.update-fees-popuop .loan-options .form-grp{
    width: 100%;
}

.update-fees-popuop .form-grp:last-child{
    margin-bottom: 0;
}

.update-fees-popuop .create-label:not(:last-child){
    border: none;
}

.update-fees-popuop .create-label:last-child{
    border: none;
}

.update-fees-popuop .create-label{
    font-size: 18px;
    margin-top: 10px;
    padding-bottom: 10px;
}

.update-fees-popuop .form-grp label{
    font-size: 18px;
}

.update-fees-popuop .radiomark::after{
    top: 2px;
}

.update-fees-popuop .optionbar_fee{
    min-width: 300px;
}

.update-fees-popuop .option_fields{
    width: 100%;
    display: block;
}

.update-fees-popuop .btn-grp{
    padding-top: 0 !important;
    padding: 20px;
}

.update-fees-popuop .accept-btn{
    font-size: 16px;
    gap: 5px;
}