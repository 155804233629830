.csv-popup {
  width: 700px;
  height: 100%;
  max-height: 360px;
  padding: 0;
}
.csv-popup .form-group {
  padding: 0;
}
.csv-popup .popup-footer {
  padding: 0 20px;
}
.csv-popup .popup-body {
  height: auto;
}
.csv-popup .clear-btn{
  text-decoration: none;
  color: var(--blue);
  padding: 10px 0;
}
