.feedback-answers{
    margin-top: 0;
    height: 100%;
}
.feedback-answers .inside-mid {
    min-height: 300px;
}
.feedback-answers .inside-content{
    min-height: 200px;
    justify-content: space-around;
}
.feedback-answers{
    font-size: 14px;
    font-family: 'ns-regular';
    color: var(--black);
}