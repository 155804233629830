.mentorFeedbackContainerOuter{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-height: calc(100vh - 60px);
    padding: 20px;
    background: var(--bg-yellow);
}

.mentorFeedbackCard{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 920px;
    width: 100%;
    border: 1px solid var(--black);
    border-radius: 10px;
    padding: 40px;
    background: var(--white);
    box-shadow: 5px 5px var(--yellow);    
}

.mentorFeedbackCardDetails .heading{
    font-size: 24px;
    font-family: 'ss-bold';
}

.mentorFeedbackCardDetails{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    max-width: 380px;
    position: relative;
}

.mentorFeedbackCardDetails textarea{
    padding: 10px;
    font-size: 12px;
    width: 100%;
    margin-top: 10px;
    border: 1px solid var(--border);
    border-radius: 5px;
    background: var(--grey-1);
}
.mentorFeedbackCardDetails textarea:focus{
    outline: 1px solid var(--grey-1);
}

.mentorFeedbackCardDetails .form-group{
    padding: 0;
    width: 100%;
}

.studentRadio{
    flex-direction: column;
    gap: 10px;
}

.mentorFeedbackCardDetails p{
    font-size: 18px;
    font-family: 'ns-semibold';
}

.mentorFeedbackCardDetails .dark-btn-a{
    padding: 15px 20px;
    justify-content: center;
}