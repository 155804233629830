.sendFeePopup {
    width: 100%;
    height: 100%;
    max-width: 420px;
    max-height: 350px;
    padding: 0;
  }
  .sendFeePopup .popup-body {
    gap: 10px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 125px);
  }
  .fee-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px;
    background: var(--white);
    border: 1px solid var(--border);
    border-radius: 5px;
    font-family: "ns-semibold";
    font-size: 13px;
    color: var(--black);
    transition: 0.2s;
    cursor: pointer;
  }
  .fee-option:hover {
    color: var(--white);
    background: var(--blue);
    border: 1px solid var(--blue);
  }
  .fee-option img:first-child {
    display: block;
  }
  .fee-option img:last-child {
    display: none;
  }
  .fee-option:hover img:last-child {
    display: block;
  }
  .fee-option:hover img:first-child {
    display: none;
  }
  .footer-links {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 3px;
    color: var(--grey);
  }
  .sendFeePopup .popup-footer {
    border-top: 1px solid var(--border);
    padding: 10px;
  }
  .footer-links a {
    font-size: 12px;
    font-family: "ns-semibold";
    color: var(--grey);
  }
  .bank-details {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .bank-details .detail {
    width: 33%;
    font-family: "ns-regular";
    font-size: 13px;
    line-height: 120%;
  }
  .bank-details .text-bold {
    width: 67%;
    font-size: 13px;
    line-height: 150%;
    font-family: "ns-bold";
  }
  .sendFeePopup .btn-group{
    padding: 0 20px 20px;
  }

  .sendFeePopup .btn-group .primary-btn{
    width: 100%;
  }