.addCourse-container{
    display: flex;
    width: calc(100% - 40px);
    margin: auto;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    max-height: calc(100vh - 120px);
    overflow: auto;
    border-radius: 10px;
    gap: 30px;
    margin-top: 20px;
}

.all-session-box{
    width: 30%;
    overflow: hidden;
}
.session-form {
    width: 65%;
}
.session-form form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--white);
}
.session-box{
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid var(--border);
    background: var(--white);
}
.session-form .form-group{
    width: 100%;
}
.editor-block{
    border: 1px solid var(--border);
    border-radius: 5px;
    width: 95%;
    margin: auto;
    height: 275px;
    overflow: auto;
}

.session-table{
    text-align: left;
    width: 100%;
    font-family: 'ns-regular';
}
.session-table thead th{
    color: var(--grey);
    border-bottom: 1px solid var(--border);
    padding: 10px 20px;
    font-size: 14px;
    font-family: 'ns-semibold';
}
.session-table tbody td{
    color: var(--black);
    padding: 10px 20px;
    font-size: 14px;
    font-family: 'ns-regular';
}
.session-box-header{
    padding: 10px 20px;
    font-family: 'ns-bold';
    background: #D4E6FF;
    border-radius: 5px 5px 0 0 ;
}
.session-form .text-2{
    font-size: 16px;
    font-family: 'ns-semibold';
    color: var(--black);
}

.session-table .btn-grp{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.session-table .btn-grp img{
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
}
.session-table tbody tr:nth-child(odd){
    background: var(--bg-color);
}
.session-table tbody tr td{
    font-family: 'ns-semibold';
    color: var(--black);
}
.session-table tbody tr:hover td{
    color: var(--blue);
}
.session-table tbody tr:hover img{
    visibility: visible;
    opacity: 1;
}