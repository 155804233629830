.create-header {
  justify-content: space-between;
}
#container-1,
#container-2 {
  height: calc(100vh - 120px);
  width: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
#container-1.show {
  display: flex !important;
}
.create-container {
  max-width: 700px;
  width: 100%;
  padding-bottom: 0;
  background: var(--white);
  border-radius: 10px;
  position: relative;
  margin: 30px auto 0;

  border: 1px solid var(--border);
}
.form-group {
  padding: 10px 25px;
  padding-bottom: 0px;
}
.create-input {
  padding: 10px;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  font-size: 13px;
  font-family: "ns-semibold";
  width: 100%;
}

.radio-group {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.radio-group .create-label {
  margin-right: 20px;
  color: var(--grey);
  position: relative;
  padding-left: 25px;
  padding-top: 4px;
  cursor: pointer;
}
.radio-btn {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: var(--white);
  border-radius: 50%;
  border: none;
  outline: none;
}
.radio-btn:focus {
  outline: none;
  border: none;
}
.radiomark {
  top: 0px;
  left: 0px;
  position: absolute;
  height: 20px;
  width: 20px;
  background: var(--white);
  border: 1px solid var(--grey);
  border-radius: 50%;
}
.radio-btn:checked ~ .radiomark {
  background: var(--white);
  border-color: var(--black);
}
.radio-btn:checked ~ .radiomark::after {
  display: block;
}
.radiomark::after {
  display: none;
  content: "";
  top: 3px;
  left: 3px;
  height: 12px;
  width: 12px;
  position: absolute;
  background: var(--black);
  border-radius: 50%;
}
.radio-group .radio-btn:checked {
  color: var(--black);
}
.checkmark {
  position: absolute;
  width: 16px;
  height: 16px;
  background: var(--white);
  left: 0px;
  top: 2px;
  border-radius: 3px;
  border: 1px solid var(--black);
}
.checkbox-label {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.checkbox-btn {
  height: 16px;
  width: 16px;
  border: 1px solid var(--dark-grey);
}
.checkbox-btn:checked {
  fill: var(--dark-grey);
}
.checkbox-btn:focus {
  outline: none;
  border: none;
}
.checkmark {
  display: none;
}
.checkmark::after {
  display: none;
}
.checkbox-btn:checked ~ .checkmark {
  display: block;
  background: var(--dark-grey);
}
.checkbox-btn:checked ~ .checkmark::after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 4px;
  width: 4px;
  height: 9px;
  border: 2px solid var(--white);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.date-time {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}

.date-sec {
  width: 48%;
}

.time-sec {
  width: 48%;
}
.form-footer {
  text-align: right;
  padding: 15px 25px;
  /* margin-top: 10px; */
}

form {
  position: relative;
}

.add-student {
  max-width: 1000px;
  margin: auto;
}
.check-row {
  width: 80px;
  padding-right: 0px !important;
}

.add-student .feedback-table thead {
  box-shadow: none;
}
.add-student .feedback-table thead tr th {
  border-bottom: 1px solid var(--border);
}
tfoot {
  bottom: 40px;
  position: fixed;
  padding: 15px;
  background: var(--white);
  width: calc(100% - 960px);
  border-top: 1px solid var(--border);
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

tfoot .danger-btn {
  font-size: 16px;
  padding: 10px 20px;
  width: auto;
}

/* question-container */

.menu-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 15px;
}

.menu-top .page-butons {
  display: flex;
  align-items: center;
}

.menu-top .page-butons a {
  margin-right: 15px;
  display: flex;
}

.menu-top .page-butons img {
  max-width: 18px;
  width: 100%;
}

.menu-top .page-butons img:last-child {
  margin-right: 0;
}

.question-container {
  height: calc(100vh - 110px);
  display: flex;
}

.inpage-side-menu {
  max-width: 300px;
  width: 100%;
  background-color: #fff;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.shell-menu {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
}

.every-question-menu {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
}

.every-question-menu .asked-questions ul {
  list-style: none;
  text-decoration: none;
  padding: 0;
}

.every-question-menu .asked-questions ul li {
  cursor: pointer;
  color: var(--black);
  text-decoration: none;
  padding: 0 12px;
  display: flex;
  align-items: center;
  height: 36px;
  border-top: 1px solid var(--border);
  font-family: "ns-regular";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
}

.every-question-menu .asked-questions ul li img {
  max-width: 12px;
  margin-right: 11px;
}

.menu-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 15px;
}

.step-home {
  height: 33px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--border);
}

.add-question {
  height: 36px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--border);
  position: relative;
  cursor: pointer;
}

.add-student .feedback-container {
  height: calc(100vh - 300px);
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}

.floating-menu.show {
  visibility: visible;
  opacity: 1;
  transition: 0.4s;
}

.floating-menu {
  visibility: hidden;
  opacity: 0;
  transition: 0.4s;
  width: 144px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--border);
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  left: 14px;
  top: 30px;
}

.floating-menu ul {
  list-style: none;
  padding: 0;
}

.floating-menu ul li img {
  max-width: 12px;
  margin-right: 11px;
}

.floating-menu ul li {
  cursor: pointer;
  color: var(--black);
  text-decoration: none;
  padding: 0 12px;
  display: flex;
  align-items: center;
  height: 35px;
  border-bottom: 1px solid var(--border);

  font-family: "ns-regular";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
}

.floating-menu ul li:last-child {
  border-bottom: none;
}

.with-icon {
  display: flex;
  align-items: flex-start;
  text-decoration: none;
}

.with-icon img {
  margin-right: 12px;
  width: 15px;
}

.main-box {
  width: calc(100% - 300px);
  height: 100%;
  background: url("https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/question-bg.png");
  background-repeat: repeat;
  /* display: flex;
    align-items: center;
    justify-content: center; */
}

.inside-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
  width: 100%;
  flex-wrap: wrap;
  overflow-y: scroll;
}
.inside-content {
  min-height: 290px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.inside-main {
  width: calc(100% - 56px);
  min-height: calc(100% - 140px);
  background: #fffdf5;
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.inside-main:last-child {
  margin-bottom: 30px;
}

.inside-top {
  min-height: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0 30px;
  width: 100%;
  position: absolute;
  top: 0;
}

.inside-shell {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  padding-top: 70px;
}

.inside-mid {
  box-sizing: border-box;
  border-radius: 10px;
  max-width: 640px;
  width: 100%;
  text-align: center;
  min-height: 340px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-style {
  background: #ffffff;
  border: 1px solid #101010;
  box-shadow: 5px 5px 0px #fad133;
}

.red-button {
  font-family: "ns-regular";

  width: 167px;
  height: 46px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: var(--red);
  text-decoration: none;

  border-radius: 5px;
}

.inside-footer {
  width: 100%;
  height: 40px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nu-buttons {
  width: 110px;
  height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: var(--bg-color);
  border-radius: 29px;
}

.nu-buttons a,
.nu-buttons p {
  color: var(--black);
  text-decoration: none;
  font-family: "ns-regular";
  font-size: 13px;
  line-height: 13px;
  font-weight: bold;
}
.footer {
  width: calc(100% - 40px);
  padding: 15px 30px;
  background: var(--white);
  margin: auto;
  border-radius: 0 0 10px 10px;
  border: 1px solid var(--border);
  border-top: none;
}
.footer .danger-btn {
  width: auto;
  font-size: 16px;
  padding: 10px 20px;
}
.show {
  display: block !important;
}

.max-w170 {
  max-width: 170px;
}

.page-butons .tooltiptext {
  top: 23px;
  left: -120%;
  width: 70px;
  padding: 5px 5px;
  border-radius: 5px;
}
button.skip-btn {
  background: none;
  border: none;
  padding: 10px;
  color: var(--dark-grey);
  text-transform: uppercase;
  cursor: pointer;
  font-family: "ns-semibold";
  font-size: 11px;
}

/* .create-header .green-btn-outline {
  position: absolute;
  right: 30px;
} */

.active-btn {
  display: flex !important;
  gap: 5px;
}
.active-btn .rotate-img {
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.pg-arrows-outer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  position: sticky;
  bottom: 0;
  right: 0;
  padding-top: 10px;
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 10px 0;
  background-color: var(--bg-color);
}
.pd-add {
  padding-right: 5px;
  padding-left: 5px;
}
.pg-arraows-p {
  font-family: "ns-regular";
  font-size: 14px;
  color: var(--dark-grey);
}
.pg-arrows {
  cursor: pointer;
}
.pg-arrows a {
  display: inline-flex;
}
.pg-arrows .pg-left {
  transform: rotate(90deg);
  padding: 5px;
}
.pg-arrows .pg-right {
  transform: rotate(-90deg);
  padding: 5px;
}
.pg-arrows .pg-left:hover,
.pg-arrows .pg-right:hover {
  display: inline-flex;
  background-color: var(--white);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.selection-bar {
  text-align: center;
  width: 100%;
  font-size: 12px;
  font-family: "ns-regular";
  line-height: 120%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.selection-bar .clear-btn {
  color: var(--blue);
  padding: 0;
  font-family: "ns-bold";
}
