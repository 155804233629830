td.highlighted{
    background: var(--light-blue);
}
.bidding-list td{
    transition: 0.3s;
    border-bottom: none !important;
}
.save-row td{
    bottom: 0;
    position: absolute;
}
.save-row button{
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    text-transform: capitalize;
    font-size: 13px;
}
td.highlighted button{
    visibility: visible;
    opacity: 1;
}