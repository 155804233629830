.mentorFeedbackContainer{
    max-width: 500px;
    width: 100%;
    padding: 0;
}

.feedbackCard{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    border-radius: 5px;
    background: var(--grey-1);
    padding: 10px;
}

.feedbackBox{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}

.feedbackCard:last-child{
    margin-top: 10px;
}

.feedbackBox p{
    gap: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    line-height: 120%;
    font-size: 13px;
    color: var(--black-3);
}
.feedbackBox p span{
    font-family: 'ns-semibold';
    color: var(--black-5);
}

.mentorFeedbackContainer .heading{
    font-family: 'ns-semibold';
}