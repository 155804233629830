.single-heading-header {
  justify-content: center;
  padding: 15px 0;
}
.single-heading-header .component-back {
  position: absolute;
  left: 20px;
}
ul.cluster-dropdown {
  visibility: hidden;
  opacity: 0;
  top: 80%;
  width: calc(100% - 50px);
  list-style: none;
  border-radius: 5px;
  background: var(--white);
  position: absolute;
  list-style: none;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 5%);
  transition: 0.3s;
  z-index: 1;
  overflow: auto;
  max-height: 180px;
}
ul.cluster-dropdown.active {
  visibility: visible;
  opacity: 1;
}

li.cluster-item {
  width: 100%;
  font-family: "ns-regular";
  font-size: 14px;
  padding: 7px 15px;
  color: var(--black);
  cursor: pointer;
  transition: 0.3s;
  overflow: hidden;
}
li.cluster-item:hover {
  background: var(--light-grey);
}
.cluster {
  width: 100%;
  position: relative;
}
.cluster .create-input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  flex-wrap: wrap;
  background-position: right 10px center;
  background-image: url(https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg);
  /* cursor: pointer; */
  background-repeat: no-repeat;
  background-size: auto 50%;
}
.selected-cluster {
  gap: 5px;
  cursor: default;
  background: #f4f4f4;
  padding: 5px 11px;
  font-size: 11px;
  font-family: "ns-semibold";
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
  border-radius: 300px;
}
.selected-cluster img {
  cursor: pointer;
}
.form-2-col {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.program {
  height: calc(100% - 150px);
}
.program .footer {
  position: absolute;
  background: none;
  border: none;
  bottom: 0;
  text-align: right;
  width: 100%;
}
