.text-dark-grey {
  color: var(--dark-grey);
}

.student-header .text-1 {
  padding: 5px 0;
  font-family: "ns-semibold";
}

.fee-stats-outer {
  border-radius: 5px;
  margin: 10px auto;
  max-width: 99%;
  padding: 12px;
  width: 100%;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fee-dropdown .text-6 {
  margin-top: 0;
  font-size: 13px;
  font-family: "ns-regular";
  color: var(--dark-grey);
  background-color: transparent;
}

.fee-stats {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: flex-end;
}

.selected-fee {
  width: 50%;
}

.fee-stats .fee-total-text,
.fee-stats .fee-amount-text,
.fee-stats .due-date-text {
  font-size: 16px;
}

.student-table-outer {
  background: var(--white);
  width: calc(100% - 40px);
  height: calc(100vh - 250px);
  /* height: 100%; */
  overflow-x: auto;
  overflow-y: scroll;
  margin: auto;
}

.edit-date {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.edit-date img {
  cursor: pointer;
}

.student-header {
  justify-content: space-between;
}

.student-header-left {
  display: flex;
  gap: 10px;
  width: 50%;
}

.student-table-outer .light-btn-a {
  min-width: auto;
}

.datePicker {
  background: var(--white);
  border: none;
  outline: none;
  padding: 10px;
  font-size: 13px;
  font-family: "ns-regular";
  color: var(--dark-grey);
  background-image: url("https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  outline: none;
  appearance: none;
  padding-right: 30px;
  border-radius: 300px;
  border: 1px solid var(--border);
}
.react-datepicker-popper{
  z-index: 1001 !important;
}