.button{
    padding: 8px 12px;
    text-align: center;
    font-size: 13px;
    line-height: 120%;
    font-family: 'ns-semibold';
    cursor: pointer;
    display: block;
    position: relative;
}

.blackBtn{
    color: var(--white);
    background: var(--black-5);
    border: 2px solid var(--white);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.whiteBtn{
    color: var(--black-5);
    background: var(--white);
    border: 2px solid var(--black-5);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.whiteBtn img{
    filter: invert(1);
}