.danger-clear-btn {
  background: transparent;
  color: var(--red);
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  padding: 8px 15px;
  line-height: 120%;
  font-family: "ns-regular";
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  min-width: 121px;
}

.split-row {
  background: transparent;
}

.shortlist {
  /* height: calc(100vh - 380px); */
  height: 100%;
}

.shortlist-new {
  height: calc(100vh - 250px);
}

.split-row.active {
  background: var(--bg-color);
}

.rotate {
  transition: 0.5s;
}

.split-row.split-row.active .rotate {
  transform: rotate(180deg);
}

.feedback-table .btn-grp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.feedback-table .btn-grp .danger-btn {
  background: transparent;
  color: var(--red);
}

.feedback-table .btn-grp .green-btn {
  color: var(--white);
  background: var(--green);
  font-size: 16px;
}

.feedback-table .btn-grp .danger-btn,
.feedback-table .btn-grp .green-btn,
.feedback-table .btn-grp .yellow-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding: 5px 10px;
  width: auto;
  text-transform: capitalize;
  font-family: "ns-regular";
  border: 1px solid;
}

.offerLetterContainer{
  overflow: auto;
  min-height: 500px;
}

.hoverElement{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  margin: 0;
}

.hoverElement p{
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.maxWidthTable.offerLetterTable{
  width: max-content;
}


.tooltipOuter{
  visibility: hidden;
  opacity: 0;
  width: max-content;
  position: absolute;
  padding: 10px;
  top: 7px;
  z-index: 2;
  right: 100%;
  border-radius: 100px;
  background: var(--primary-5);
  transition: 0.4s;
}

.toastRow:hover .tooltipOuter{
  opacity: 1;
  visibility: visible;
}

.tooltipText{
  color: var(--white);
  font-size: 14px;
}

.tooltipOuter::after{
  content: "";
  position: absolute;
  right: -3px;
  top: 40%;
  width: 10px;
  height: 10px;
  background: var(--primary-5);
  transform: rotate(45deg);
}
.offerLetterTable tr th:first-child{
  position: sticky;
  left: 0;
  z-index: 1;
}
.offerLetterTable tr th:last-child{
  position: sticky;
  right: 0;
  z-index: 1;
}

.offerLetterTable tr td:first-child{
  position: sticky;
  left: 0;
  z-index: 1;
  background: var(--white);
}
.offerLetterTable tr td:last-child{
  position: sticky;
  right: 0;
  z-index: 1;
  background: var(--white);
}

.tableOverflow{
  overflow: visible;
}