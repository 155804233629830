.mail-popped {
    max-width: 1000px;
    width: 100%;
    height: auto;
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
    display: none;
    height: calc(100vh - 100px);
}

.mail-popped.active{
    display: block;
}

.close-btn {
    cursor: pointer;
}

.mail-head-shell {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.mail-head-shell .mail-head-shell-inside {
    width: 49%;
}

.mail-popped .popup-body {
    height: calc(100% - 240px);
    padding: 0 20px;
}

.mail-popped.withedit .popup-body {
    height: calc(100% - 280px);
}

.mail-popped .popup-body.only-img {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: calc(100% - 30vh);
    overflow: auto;
}

.mail-popped .popup-body img {
    max-width: 100%;
}

.popup-dropdown {
    width: 100%;
    border: 1px solid var(--light-grey);
    border-radius: 5px;
}

.width-100 {
    width: 100%;
}

.test-mail {
    display: flex;
    justify-content: space-between;
}

.test-mail input {
    background: var(--white);
    padding: 10px;
    font-size: 13px;
    font-family: "ns-regular";
    color: var(--dark-grey);
    outline: none;
    appearance: none;
    padding-right: 30px;
    width: 100%;
    border: 1px solid var(--light-grey);
    border-radius: 5px;

    max-width: 88%;
    width: 100%;
}

.test-mail .secondary-btn {
    border-radius: 5px;
}
.mail-popup-footer {
    padding: 20px;
    position: absolute;
    width: 100%;
    bottom: 0;
    background: var(--white);

    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--border);
    padding-top: 10px !important;
}

.skip-mail-offerletter{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2px !important;
    cursor: pointer;
    font-family: 'ns-regular';
    font-size: 16px;
}

.ckeditorPopupOutr table p{margin: 10px 0 !important}