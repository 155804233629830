.due-popup {
  width: 420px;
  height: 370px;
  padding: 0;
}

.due-popup .popup-heading {
  padding: 20px;
  padding-bottom: 10px;
}
.due-popup .popup-body {
  padding: 20px 20px;
}
.due-popup .popup-footer {
  width: 100%;
  position: absolute;
  bottom: 30px;
  padding: 0 20px;
}
.due-popup .primary-btn {
  width: 100%;
}
.form-2-grp{
  display: flex;
  flex-direction: row !important;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  font-size: 13px;
  font-family: "ns-semibold";
}
.form-2-grp input{
  width: 70%;
}
.amount{
  margin-top: 20px;
  font-size: 18px;
  font-family: 'ns-bold';
  color: var(--green);
  display: block;

}

label.gstCustom {
  text-align: right;
  margin-top: 5px;
  font-size: 12px !important;
  font-family: "ns-regular" !important;
  position: absolute;
  right: 10px;
  top: 30px;
}
.pr-85{ padding-right: 85px;}