.addStudentPopup .popup-body{
    padding: 0;
}
.addStudentPopup .feedback-container{
    width: 100%;
    margin: 0;
    height: calc(100vh - 335px);
    border: none;
}
.addStudentPopup .feedback-table thead{
    box-shadow: none;
}
.addStudentPopup .feedback-table tr td{
    border-bottom: none;
}
.popup-container.addStudentPopup {
    height: calc(100vh - 100px);
    overflow: hidden;
}
.addStudentPopup .popup-body{
    height: auto;
}