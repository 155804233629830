.faculty-details {
  width: 75%;
  font-family: "ns-regular";
}
.faculty-details b {
  font-family: "ns-bold";
}
.custom-width {
  width: 15%;
}
.feedback-table .primary-text {
  font-size: 14px;
  color: var(--blue) !important;
}
.course-dropdown {
  top: 20px;
  width: 170px;
}
.course-table-outer {
  height: calc(100vh - 300px);
}