
.centerHeading {
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
}
.centerHeading .component-back{
    position: absolute;
    left: 20px;
}

.toggleBtns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    font-size: 14px !important;
    font-family: 'ns-semibold' !important;
}

.toggleBtns .primary-text{
    font-size: 14px;
}

.addSessionContainer{
    padding: 20px;
}

.financeRequestContainer{
    padding: 0;
    height: calc(100vh - 170px) !important;
}

.expenceTableOuter{
    width: calc(100% - 40px);
    margin: 0 auto;
}

.success-btn-new{
    cursor: pointer;
    background: var(--green);
    border: 1px solid var(--green);
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 13px;
    border-radius: 300px;
    font-family: "ns-semibold";
    color: var(--white);
    justify-content: flex-start;
}

.reject-btn-new{
    width: 100%;
    justify-content: center
}
