.feedback-report {
  max-width: 1000px;
  height: 700px;
  overflow: hidden;
}
table.pop-up-table {
  width: 100%;
  text-align: left;
  border-radius: 5px;
}

.pop-up-table thead {
  font-family: "ns-semibold";
  font-size: 14px;
  position: sticky;
  top: 0;
}

.pop-up-table thead th {
  padding: 15px 10px;
  background: var(--bg-color);
}

.pop-up-table td {
  padding: 15px 10px;
  font-size: 14px;
  font-family: "ns-semibold";
}
.pop-up-table thead th {
  width: 24%;
}
.close-btn {
  cursor: pointer;
}
