.fee .club-header {
  max-height: 290px;
}
.fee .header-bottom {
  height: calc(100% - 72%);
}
.fee .header-top {
  height: 72%;
}
.fee-container {
  max-width: 900px;
}
.fee-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
}
.fee-total {
  display: flex;
  gap: 5px;
  align-items: center;
}
.fee-amount {
  display: flex;
  gap: 5px;
  align-items: center;
}

.due-date {
  display: flex;
  align-items: center;
  gap: 5px;
}
.fee-body {
  margin-top: 10px;
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.fee-type-text {
  font-size: 16px;
  line-height: 120%;
  font-family: "ns-regular";
  text-transform: capitalize;
}
.fee-total-text {
  font-size: 14px;
  line-height: 120%;
  font-family: "ns-regular";
  color: var(--black);
}

.fee-amount-text {
  font-size: 14px;
  line-height: 120%;
  font-family: "ns-regular";
  color: var(--green);
}

.due-date-text {
  font-size: 14px;
  font-family: "ns-regular";
  line-height: 120%;
  color: var(--red);
}
p.fee-type-text {
  font-size: 16px;
  line-height: 120%;
  color: var(--blue);
  font-family: "ns-semibold";
  text-transform: capitalize;
}

.fee-amount-text {
  font-size: 14px;
  line-height: 120%;
  font-family: "ns-regular";
  color: var(--green);
}

p.due-date-text {
  font-size: 14px;
  font-family: "ns-regular";
  line-height: 120%;
  color: var(--red);
}

.fee-box-left {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.fee-box-right {
  margin-top: 10px;
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dark-btn-a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  background: var(--black);
  color: var(--white);
  font-size: 14px;
  padding: 8px 15px;
  line-height: 120%;
  font-family: "ns-regular";
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.light-btn-a {
  background: var(--bg-color);
  color: var(--black);
  border: 1px solid var(--border);
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  padding: 8px 15px;
  line-height: 120%;
  font-family: "ns-regular";
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  min-width: 121px;
}
.fee-body .action-btn {
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
}
.fee-box .action-dropdown {
  right: 3%;
  top: 0px;
}
.fee-box-outer {
  display: flex;
  flex-direction: column;
  width: 48%;
  background: var(--white);
  padding: 20px;
  height: 100%;
  border-radius: 10px;
  border: 1px solid var(--border);
}
.fee-box-outer.active {
  max-height: 100%;
}
.installment-section {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
}
.installment-box {
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  background: var(--bg-color);
  overflow: hidden;
}
.installment-heading {
  font-size: 16px;
  line-height: 120%;
  font-family: "ns-semibold";
  padding-bottom: 10px;
  border-bottom: 1px solid var(--border);
}

.installment-table {
  width: 100%;
}

.installement-table-outer {
  width: 100%;
}
.installment-table thead td {
  font-size: 13px;
  color: var(--grey);
  line-height: 120%;
  font-family: "ns-regular";
  padding: 5px 0;
}
.installment-table tbody td {
  font-size: 15px;
  line-height: 120%;
  font-family: "ns-regular";
  padding: 5px 0;
  color: var(--black);
}
.light-btn-a img {
  transition: 0.2s ease;
}
.rotate-img {
  transition: 0.2s ease;
  transform: rotate(180deg);
}
.header-bottom .student-inner-right {
  max-width: 50%;
}

.fee-box .student-inner-right {
  margin-top: 15px;
  max-width: 100%;
  width: 100%;
  justify-content: flex-start;
}
.fee-box .due-date-text {
  font-family: "ns-regular";
  color: var(--dark-grey);
}
.fee-box .due-date-text b {
  font-family: "ns-bold";
}
.student-inner-right .fee-box {
  gap: 5px;
}
.installment-btn-grp {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.installment-btn-grp .light-btn-a,
.installment-btn-grp .dark-btn-a {
  width: 49%;
  align-items: center;
  justify-content: center;
}
.fee-info{
  align-items: center !important;
  justify-content: space-between;
  flex-direction: row !important;
}
.fee-info .fee-box-right{
  margin-top: 0;
  width: fit-content;
  justify-content: flex-end;
}
.fee-info .fee-type{
  display: flex;
  align-items: center;
  gap: 10px;
}
.fee-info .fee-box-left{
  width: fit-content;
}
.fee-info .text-dark {
  font-family: 'ns-bold';
}