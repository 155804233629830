.uploadbox_wrapper{
    text-align: center;
    width: 100%;
    position: relative;
}

.upload_shell{
    outline: 1px solid var(--light-black);
    max-width: 340px;
    width: 100%;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-family: "ns-semibold";
    position: relative;
    background: var(--white);
    border-radius: 5px;
    flex-wrap: wrap;
    z-index: 9;
}

.upload_shell::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    z-index: -1;
}

.upload_shell::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0;
    height: 100%;
    background-color: #fad133;
    border-radius: 5px;
    z-index: -9;
    transition: 1.5s ease-in-out;
}

.upload_shell.active::before{
    width: 100%;
}

.uploadbox_wrapper label{
    width: 100%;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
    padding-left: 10px;
    text-align: left;
}

.upload_inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.uploadbox_wrapper label img{
   margin: 0; 
   
}

.uploadicons {
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    cursor: pointer;
    right: 0;
    top: 0;
    height: 100%;
    width: 48px;
    border-left: 1px solid var(--light-black);
}

.uploadbox{
    position: absolute;
    opacity: 0;
    height: 100%;
    cursor: pointer;
    width: 0%;
    left: 0;
}