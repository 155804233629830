.form-2-col .form-group {
  width: 100%;
  padding: 10px 0px;
}
.form-2-col {
  gap: 15px;
  padding: 0 25px;
  align-items: flex-end;
}
.addCourse {
  max-width: 800px;
}
.form-2-col .cluster-dropdown {
  width: 100%;
}
.addCourse .footer {
  border: none;
  padding: 15px 0;
  text-align: right;
}
.text-right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.addCourse .form-2-col .form-group{
  padding: 0;
}

.primary-outline-btn {
  font-size: 14px;
  font-family: "ns-semibold";
  border: 1px solid var(--blue);
  background: var(--white);
  border-radius: 5px;
  padding: 8px 16px;
  color: var(--blue);
  cursor: pointer;
  transition: all 0.3s;
}
.primary-outline-btn:hover {
  background: var(--blue);
  color: var(--white);
}
.form-upload {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0px 25px;
}
.form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.form .upload-btn-wrapper {
  width: 75%;
}
.form .text-2{
  font-size: 13px;
  font-family: 'ns-bold';
}
.form .primary-outline-btn{
  padding: 8px 25px;
}
.form-footer-new{
  width: 100%;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
