.mentor-details {
  padding: 15px 20px;
  background: var(--white);
}
.mentor-name {
  font-size: 14px;
  line-height: 120%;
  color: var(--grey);
  font-family: "ns-semibold";
}
.approve-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  background: var(--green);
  padding: 6px 10px;
  font-size: 13px;
  font-family: "ns-regular";
  color: var(--white);
  outline: none;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}
tr.active {
  background: rgba(0, 78, 154, 0.05);
}
.text-grey {
  color: var(--grey);
}

tr.disableMeeting {
  pointer-events: none;
  opacity: 0.5;
  background: #eee;
  text-decoration: line-through;
}

.optional-green-btn{
  font-size: 14px;
  font-family: "ns-semibold";
  border: 1px solid var(--green);
  background: var(--green);
  border-radius: 300px;
  padding: 10px 15px;
  color: var(--white);
  cursor: pointer;
  transition: 0.3s;
  text-transform: capitalize;
}

.stripe{
  text-decoration: line-through;
}
/* tr.disableMeeting td {text-decoration: line-through;} */


.meetingListContainer{
  height: calc(100vh - 180px);
}

.new-sos-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  line-height: normal;
  background: var(--red);
  padding: 6px 10px;
  font-size: 13px;
  font-family: "ns-regular";
  color: var(--white);
  outline: none;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  min-width: 70px;
  text-align: center;
}

.sos-flexbox{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}