/* .BlankScreen-Wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.BlankScreen-Wrapper {
    width: calc(100% - 40px);
    height: calc(100vh - 180px);
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white);
}

.BlankScreen-Wrapper .BlankScreen{
    max-width: 348px;
    width: 100%;
    text-align: center;
}

.BlankScreen-Wrapper .BlankScreen img{
    max-width: 100%;
    margin-bottom: 20px;
}

.blank-heading{
    font-family: "ns-bold";
    font-size: 24px;
    line-height: 29px;
    color: var(--light-black);
    margin-bottom: 10px;
}
