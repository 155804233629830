.bulk-header {
  justify-content: center;
}
.bulk-header .component-back {
  position: absolute;
  left: 20px;
}
.checklist-outer {
  padding: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  max-height: 500px;
  overflow: auto;
  height: 100%;
}
.checklist {
  display: flex;
  gap: 10px;
  align-items: baseline;
}
.checklist-content h4 {
  font-size: 16px;
  line-height: 1.4;
  font-family: "ns-bold";
}
.checklist-content p {
  font-size: 12px;
  line-height: 1.4;
  font-family: "ns-regular";
}
.create-container-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  border-top: 1px solid var(--border);
}
.checklist-outer .heading {
  font-size: 20px;
  font-family: "ns-bold";
}

button.primary-btn-a:disabled {
  cursor: auto;
  background: var(--disabled-primary);
}

.upload-file-outer {
  padding: 20px;
}
.upload-file-box {
  width: 100%;
  margin: auto;
  padding: 10px;
  border: 2px dashed var(--border);
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "ns-semibold";
}
.upload-footer {
  justify-content: space-between;
}
.upload-footer .clear-btn {
  color: var(--green);
  font-family: "ns-bold";
}
.uploaded-file{
  padding: 30px 0;
  width: 100%;
  margin: auto;

  border: 2px dashed var(--border);
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "ns-bold";
}