/* .interview-table th,
.interview-table td {
  width: 30%;
} */
.interview-table .action-dropdown {
  right: 61%;
}
.primary-light-btn {
  background: var(--white);
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 6px 8px;
  color: var(--blue);
  font-size: 15px;
  font-family: "ns-semibold";
  line-height: 120%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: max-content;
}
.success-light-btn {
  background: var(--tag-publish);
  border: none;
  outline: none;
  color: var(--green);
  font-size: 15px;
  font-family: "ns-semibold";
  line-height: 120%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.success-light-btn-b {
  background: var(--white);
  border: none;
  outline: none;
  color: var(--green);
  font-size: 15px;
  font-family: "ns-semibold";
  line-height: 120%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.success-light-btn-a {
  border-radius: 300px;
  background: var(--tag-publish);
  border: none;
  outline: none;
  padding: 6px 12px;
  color: var(--green);
  font-size: 15px;
  font-family: "ns-semibold";
  line-height: 120%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.danger-light-btn {
  background: var(--white);
  border: none;
  outline: none;
  color: var(--red);
  font-size: 15px;
  font-family: "ns-semibold";
  line-height: 120%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.programDetail{
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 10px;
  flex-direction: row;
}
.programDetailText{
  display: flex;
  flex-direction: column;
}
.programDetail p{
  font-size: 12px;
  color: var(--grey);
}

.DeferCourse{
  color: var(--black);
  background: var(--tag-draft);
  padding: 3px 8px;
  font-size: 10px;
  border-radius: 5px;
}
.expiredCourse{
  color: var(--red);
  background: var(--tag-expired);
  padding: 3px 8px;
  font-size: 10px;
  border-radius: 5px;
}
.activeCourse{
  color: var(--green);
  background: var(--tag-publish);
  padding: 3px 8px;
  font-size: 10px;
  border-radius: 5px;
}

.tooltipOuter{
  visibility: hidden;
  opacity: 0;
  width: max-content;
  position: absolute;
  padding: 10px;
  top: 7px;
  z-index: 2;
  right: 100%;
  border-radius: 100px;
  background: var(--primary-5);
  transition: 0.4s;
}

.toastRow:hover .tooltipOuter{
  opacity: 1;
  visibility: visible;
}

.tooltipText{
  color: var(--white);
  font-size: 14px;
}

.tooltipOuter::after{
  content: "";
  position: absolute;
  right: -3px;
  top: 40%;
  width: 10px;
  height: 10px;
  background: var(--primary-5);
  transform: rotate(45deg);
}

.hoverElement{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  margin: 0;
}

.hoverElement p{
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

