.loan-popup {
  padding: 0px;
  width: 100%;
  max-width: 800px;
  height: 100%;
  max-height: 600px;
}
.loan-popup-body {
  display: flex;
  padding: 0px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  max-height:calc(100% - 100px)
}
.loan-steps-outer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 25%;
  padding-left: 20px;
}
.loan-step-content {
  padding-right: 20px;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  max-height: calc(100% - 1vh);
  overflow: auto;
}
.border-box {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid var(--border);
}
.box-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.box-left .heading {
  font-size: 18px;
  color: var(--dark-grey);
  line-height: 120%;
  font-family: "ns-semibold";
}
.loan-step {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  position: relative;
}
.loan-step .number {
  padding: 8px 14px;
  color: var(--grey);
  font-family: "ns-regular";
  border-radius: 50%;
  background: var(--white);
  border: 1px solid var(--grey);
}
.loan-step.active .number {
  border-color: var(--green);
}
.loan-step.active .number,
.loan-step.active .text {
  color: var(--green);
}
.loan-step .text {
  font-family: "ns-semibold";
  color: var(--grey);
  font-size: 14px;
}
.loan-step:not(:last-child)::before {
  content: "";
  width: 0px;
  top: 100%;
  left: 11%;
  position: absolute;
  height: 40px;
  border-right: 1px solid var(--grey);
}
.loan-step.active:not(:last-child)::before {
  border-color: var(--green);
}
.box-left .text {
  margin-top: 5px;
  font-size: 14px;
  line-height: 120%;
  color: var(--grey);
  font-family: "ns-regular";
}
.box-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  width: 50%;
}
.reject-btn {
  cursor: pointer;
  background: transparent;
  border: 1px solid var(--red);
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 13px;
  border-radius: 300px;
  font-family: "ns-semibold";
  color: var(--red);
  justify-content: flex-start;
}
.accept-btn {
  cursor: pointer;
  background: var(--green);
  border: 1px solid var(--green);
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 13px;
  border-radius: 300px;
  font-family: "ns-semibold";
  color: var(--white);
  justify-content: flex-start;
}
.box-body {
  background: var(--bg-color);
  padding: 10px;
  width: 100%;
  font-size: 13px;
  line-height: 150%;
  font-family: "ns-regular";
  border-radius: 10px;
}
.approved-tag {
  border-radius: 300px;
  font-size: 13px;
  background: var(--tag-active);
  color: var(--green);
  font-family: "ns-semibold";
  padding: 8px 12px;
}
.pending-tag {
  border-radius: 300px;
  font-size: 13px;
  background: var(--tag-expired);
  color: var(--red);
  font-family: "ns-semibold";
  padding: 8px 12px;
}
.border-box .green-btn {
  font-size: 13px;
  padding: 8px 12px;
  text-transform: capitalize;
}
.box-upload-outer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  margin-top: 20px;
}
.box-upload-box {
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 49%;
  padding: 10px;
  background: var(--bg-color);
}
.box-upload-box .box-upload-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.box-upload-box .file-name {
  font-size: 13px;
  color: var(--dark-grey);
  font-family: "ns-semibold";
}
.box-upload-box .file-size {
  font-size: 10px;
  color: var(--grey);
  font-family: "ns-regular";
}
.box-upload-text {
  display: flex;
  flex-direction: column;
}
.download-btn {
  cursor: pointer;
}
.loan-popup .popup-footer {
  padding: 0 20px;
}
.light-skip-btn {
  font-size: 13px;
  font-family: "ns-semibold";
  background: var(--border);
  border: 1px solid var(--border);
  color: var(--black);
  padding: 10px 20px;
  border-radius: 300px;
  cursor: pointer;
}
.border-box select {
  border-radius: 5px;
  width: 100%;
  max-width: 115px;
  background-color: var(--bg-color);
}
.loan-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.loan-options .form-grp {
  width: 48%;
  margin-bottom: 0;
}
.loan-options .form-grp select {
  max-width: 100%;
  padding: 12px 10px;
}
.border-box .green-btn-a {
  max-width: 140px;
  padding: 8px 12px;
  font-size: 13px;
  text-align: right;
  float: right;
}
.popup-footer .green-btn {
  text-transform: capitalize;
}
.loan-popup-body .btn-grp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.box-application-body{
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.application-detail{
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.application-detail .label{
  font-size: 14px;
  color: var(--grey);
  font-family: 'ns-regular';
}
.application-detail .info{
  font-size: 14px;
  color: var(--black);
  font-family: 'ns-regular';
}
.application-detail.w-100{
  width: 100%;
}
