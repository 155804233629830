.checkmark-wrapper {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 30px auto;
}

.checkmark-wrapper.col-3 .checkmark-label {
  flex: 0 0 32%;
  margin-bottom: 0;
}
.checkmark-wrapper.col-3 .checkmark-label:last-child {
  margin-right: 0;
}
.checkmark-label {
  position: relative;
  margin-right: 0;
  margin-bottom: 10px;
  color: var(--light-black);
  position: relative;
  padding: 13px 10px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-family: "ns-semibold";
  display: flex;
  align-items: center;
  border: 1px solid var(--light-black);
  border-radius: 10px;
}

.checkmark-label:last-child {
  margin-bottom: 0;
}

.checkmark-label.active {
  background: var(--light-yellow);
}
