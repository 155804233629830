#clubcontainer-1.show, #clubcontainer-2.show, #clubcontainer-3.show{
    display: flex !important;
}

#clubcontainer-1{
    height: calc(100vh - 120px);
    width: 100%;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

#clubcontainer-2{
    height: calc(100vh - 120px);
    width: 100%;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

#clubcontainer-3{
    height: calc(100vh - 120px);
    width: 100%;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.create-club-container.club_member_wrapper{
    background: transparent;
    border: none;
    max-width: 1000px;
}

.create-club-container.club_member_wrapper .feedback-table thead{
    position: static;
}

.create-club-container.club_member_wrapper .feedback-table td{
    padding: 10px 30px;
}

.create-club-container.club_member_wrapper .feedback-table thead th{
    padding: 20px 30px;
}

.create-club-container{
    max-width: 700px;
    width: 100%;
    padding-bottom: 0;
    background: var(--white);
    border-radius: 10px;
    position: relative;
    margin: auto;
    border: 1px solid var(--border);
}

.club_detail_wrapper{
    border-radius: 10px;
    border: 1px solid var(--border);
    background: var(--white);
    overflow: hidden;
}

.club_detail_wrapper .photo_section{
    height: 200px;
    background: var(--border);
    position: relative;
    background-size: cover;
    background-image: url("https://images.unsplash.com/photo-1644458729023-aacfb3f92996?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxN3x8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60");
}

.profile_photo{
    position: absolute;
    width: 80px;
    height: 80px;
    bottom: -10px;
    left: 15px;
    border-radius: 5px;
    /* border: 1px solid #DFDFDF; */
}

.profile_photo img{
    max-width: 100%;
    object-fit: cover;
}

.profile_photo .add_image{
    position: absolute;
    background: var(--white);
    border: 1px solid var(--scroll-thumb);
    border-radius: 50%;
    max-width: 26px;
    max-height: 26px;
    width: 100%;
    height: 100%;
    bottom: -6px;
    right: -6px;
    object-fit: none;
    cursor: pointer;
}

.cover_photo {
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 30px;
    background: var(--white);
    cursor: pointer;
}

.cover_photo label{
    display: flex;
    align-items: center;
    height: 32px;
    padding: 5px 10px;
    cursor: pointer;
}

.cover_photo img {
    margin-right: 6px;
}

.club_detail_wrapper form{
    padding: 0 25px 20px 25px;
}

.club_detail_wrapper .form-group{
    padding: 0;
}

.club_detail_wrapper .form-footer{
    padding: 0;
}


.club_report_box{
    padding: 5px 10px 10px;
    background: var(--bg-color);
    border-radius: 5px;
    overflow: hidden;
}

#profile_input, #bg_profile_input{
    display: none;
}

.club_report_table thead th:first-child{
    width: 35%;
}

.club_report_table thead th{
    background: transparent;
}

.club_report_box_list{
    background: var(--white);
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid var(--border);
}

.club_report_table tbody td{
    border-top: 1px solid var(--border);
    font-size: 10px;
    padding: 8px 10px;
}

.club_report_table tbody td.table-highlight{
    font-size: 13px;
    color: var(--dark-grey);
}

.club_report_table tbody tr{
    transition: 0.4s;
}

.club_report_table tbody tr:hover{
    background: var(--bg-color);
    transition: 0.4s;
}

.custom_upload_box{
    position: relative;
    width: 100%;
    padding: 4px 12px;
    background: var(--bg-color);
    border-radius: 5px;
    color: var(--grey);
    font-family: "ns-regular";
    font-size: 13px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom_upload_box.active{
    color: var(--green);
}

.custom_upload_box button{
    background: var(--white);
    box-shadow: none;
    outline: none;
    border-color: transparent;
    border: 1px solid var(--border);
    border-radius: 35px;
    font-size: 12px;
    font-family: "ns-semibold";
    color: var(--black);
    padding: 10px;
    margin-right: 5px;
    display: inline-block;
    cursor: pointer;
}

#custom_upload_button{
    display: none;
}

.club_report_box .custom_upload_box{
    padding: 0;
}

.club-member-container .filter-bar{
    padding: 10px 0;
}

.club-member-container .feedback-container {
    height: 100%;
    margin: 0;
    width: auto;
    border-bottom: none;
    background: transparent;
}

.club-member-container .feedback-table tr th{
    padding: 15px 15px;
}

.club-member-container .feedback-table tr td{
    padding: 9px 15px;
}

.club-member-container .form-footer{
    background: transparent
}

.custom_upload_box .deleted_icon{
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
}

.custom_upload_box .deleted_icon.club_report{
    right: 0;
}

.club_member_shell{
    background: var(--white);
    border: 1px solid var(--border);
    border-radius: 5px;
    overflow: hidden;
}

/* .club-member-container .club_member_shell .check-row{
    padding-left: 30px;
    padding-top: 20px;
}

.club-member-container .feedback-table tr td.club-check-box{
    padding: 10px 15px;
    padding-left: 30px;
    padding-top: 15px;
} */

.club_member_wrapper .form-footer{
    padding-top: 0;
}

.save-button{
    display: flex;
    align-items: center;
    font-size: 13px;
    font-family: "ns-regular";
    gap: 5px;
    background-color: var(--white);

    position: absolute;
    right: 20px;
    max-height: 32px;

    padding: 10px 20px;
    color: var(--light-black);

    border-radius: 20px;
    border: 1px solid var(--light-black);
    cursor: pointer;
}

.save-button img{
    max-width: 14px;
    width: 100%;
}